import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  DescriptionTable,
  IconCheckmark,
  IconDuplicate,
  IconDelete,
  IconDisabled,
  IconEdit,
  List,
  SelectedCustomFieldsTags,
  SelectedFeaturesTags,
  TooltipHeader,
} from 'shared/components';
import {
  displayValue,
  formatDate,
  capitalizeFirstLetter,
  displayMaxOveragesValue,
} from 'shared/helpers';
import { defaultDateFormat } from 'shared/constants';
import {
  showConsumption,
  showValidity,
  showValidityDuration,
  hasSelectedProductFeatures,
  hasSelectedCustomFields,
  displayExpirationDate,
} from './helpers';

const SelectedProductsTable = ({
  deleteProductFromList,
  handleProductEdit,
  handleProductDuplicate,
  selectedProducts,
  showActionBtns,
}) => {
  const { t } = useTranslation();
  const [tableExpanded, setTableExpanded] = useState({});

  return (
    <div className="SelectedProductsTable">
      <List
        data={selectedProducts}
        onExpandedChange={(expanded) => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={(row) => (
          <div className="SubComponent">
            <DescriptionTable
              details={[
                // show validity and expiration if time-limited or subscription license
                {
                  label: showValidity(
                    get(row, 'original.selected_license_policy'),
                  )
                    ? t('Validity Period')
                    : null,
                  value: displayValue(
                    showValidityDuration(
                      get(row, 'original.selected_license_policy'),
                    ),
                  ),
                },
                {
                  label: displayExpirationDate(
                    get(row, 'original.selected_license_policy'),
                  )
                    ? TooltipHeader(t('Expiration Date'))
                    : null,
                  value: formatDate(
                    get(
                      row,
                      'original.selected_license_policy.validity_period',
                    ),
                    defaultDateFormat,
                  ),
                },
                {
                  label: get(
                    row,
                    'original.selected_license_policy.allow_grace_period',
                  )
                    ? t('Grace period')
                    : null,
                  value: `${displayValue(get(row, 'original.selected_license_policy.grace_period'))} ${t('hours')}`,
                },
                // show consumption values
                {
                  label: showConsumption(
                    get(row, 'original.selected_license_policy'),
                  )
                    ? t('Max consumptions')
                    : null,
                  value: get(
                    row,
                    'original.selected_license_policy.allow_unlimited_consumptions',
                  )
                    ? t('Unlimited')
                    : displayValue(
                        get(
                          row,
                          'original.selected_license_policy.max_consumptions',
                        ),
                      ),
                },
                {
                  label: showConsumption(
                    get(row, 'original.selected_license_policy'),
                  )
                    ? t('Allow negative consumptions')
                    : null,
                  value: get(
                    row,
                    'original.selected_license_policy.allow_negative_consumptions',
                  )
                    ? t('Yes')
                    : t('No'),
                },
                {
                  label: showConsumption(
                    get(row, 'original.selected_license_policy'),
                  )
                    ? t('Allow overages')
                    : null,
                  value: get(
                    row,
                    'original.selected_license_policy.allow_overages',
                  )
                    ? t('Yes')
                    : t('No'),
                },
                {
                  label: showConsumption(
                    get(row, 'original.selected_license_policy'),
                  )
                    ? t('Max overages')
                    : null,
                  value: displayMaxOveragesValue(
                    get(row, 'original.selected_license_policy'),
                  ),
                },
                {
                  label: showConsumption(
                    get(row, 'original.selected_license_policy'),
                  )
                    ? t('Reset consumption')
                    : null,
                  value: get(
                    row,
                    'original.selected_license_policy.reset_consumption',
                  )
                    ? t('Yes')
                    : t('No'),
                },
                {
                  label: showConsumption(
                    get(row, 'original.selected_license_policy'),
                  )
                    ? t('Consumption period')
                    : null,
                  value: displayValue(
                    capitalizeFirstLetter(
                      t(
                        get(
                          row,
                          'original.selected_license_policy.consumption_period',
                        ),
                      ),
                    ),
                  ),
                },
                // show everything else
                {
                  label: get(
                    row,
                    'original.selected_license_policy.enable_maintenance_period',
                  )
                    ? t('Maintenance duration')
                    : null,
                  value: displayValue(
                    get(
                      row,
                      'original.selected_license_policy.maintenance_duration',
                    ),
                  ),
                },
                {
                  label: t('Prevent virtual machine'),
                  value: get(row, 'original.selected_license_policy.prevent_vm')
                    ? t('Yes')
                    : t('No'),
                },
                {
                  label: get(
                    row,
                    'original.selected_license_policy.is_floating',
                  )
                    ? t('Offline floating license')
                    : null,
                  value: get(
                    row,
                    'original.selected_license_policy.is_floating',
                  )
                    ? t('Yes')
                    : t('No'),
                },
                {
                  label: get(
                    row,
                    'original.selected_license_policy.is_floating_cloud',
                  )
                    ? t('Is floating cloud')
                    : null,
                  value: get(
                    row,
                    'original.selected_license_policy.is_floating_cloud',
                  )
                    ? t('Yes')
                    : t('No'),
                },
                {
                  label:
                    get(row, 'original.selected_license_policy.is_floating') ||
                    get(
                      row,
                      'original.selected_license_policy.is_floating_cloud',
                    )
                      ? t('Max simultaneous license users')
                      : null,
                  value: displayValue(
                    get(row, 'original.selected_license_policy.floating_users'),
                  ),
                },
                {
                  label:
                    get(row, 'original.selected_license_policy.is_floating') ||
                    get(
                      row,
                      'original.selected_license_policy.is_floating_cloud',
                    )
                      ? t('Floating timeout')
                      : null,
                  value: displayValue(
                    get(
                      row,
                      'original.selected_license_policy.floating_timeout',
                    ),
                  ),
                },
                {
                  label: get(row, 'original.selected_license_policy.can_borrow')
                    ? t('Can borrow')
                    : null,
                  value: get(row, 'original.selected_license_policy.can_borrow')
                    ? t('Yes')
                    : t('No'),
                },
                {
                  label: get(row, 'original.selected_license_policy.can_borrow')
                    ? t('Max borrow time')
                    : null,
                  value: `${displayValue(get(row, 'original.selected_license_policy.max_borrow_time'))} ${t('hours')}`,
                },
                {
                  label:
                    get(row, 'original.authorization_method') === 'user'
                      ? t('Max license users')
                      : null,
                  value:
                    get(
                      row,
                      'original.selected_license_policy.unlimited_max_license_users',
                    ) ||
                    get(
                      row,
                      'original.selected_license_policy.max_license_users',
                    ) === 0
                      ? t('Unlimited')
                      : displayValue(
                          get(
                            row,
                            'original.selected_license_policy.max_license_users',
                          ),
                        ),
                },
                {
                  label: hasSelectedProductFeatures(get(row, 'original'))
                    ? t('Product features')
                    : null,
                  value: (
                    <SelectedFeaturesTags
                      features={get(row, 'original.selected_features')}
                      product={get(row, 'original')}
                    />
                  ),
                },
                {
                  label: hasSelectedCustomFields(get(row, 'original'))
                    ? t('Custom fields')
                    : null,
                  value: (
                    <SelectedCustomFieldsTags
                      cFields={get(row, 'original.selected_custom_fields')}
                    />
                  ),
                },
                {
                  label: get(
                    row,
                    'original.selected_license_policy.allow_trial',
                  )
                    ? t('Trial validity')
                    : null,
                  value: `${get(row, 'original.selected_license_policy.trial_days')} days`,
                },
                {
                  label: get(
                    row,
                    'original.selected_license_policy.max_transfers',
                  )
                    ? t('Device transfer limit')
                    : null,
                  value: displayValue(
                    get(row, 'original.selected_license_policy.max_transfers'),
                  ),
                },
              ]}
            />
          </div>
        )}
        columns={[
          {
            expander: true,
            Header: t('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none',
            },
          },
          {
            accessor: 'product_name',
            Header: t('Product'),
            className: 'text-center',
            headerClassName: 'text-center',
          },
          {
            accessor: 'selected_license_policy.default_license_type',
            Header: t('License Type'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (rowData) => displayValue(t(rowData.value)),
          },
          {
            accessor: 'selected_license_policy.max_activations',
            Header: t('Max activations'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (rowData) => {
              const isUnlimited = get(
                rowData,
                'original.selected_license_policy.allow_unlimited_activations',
              );
              if (isUnlimited) {
                return t('Unlimited');
              }
              return displayValue(rowData.value);
            },
          },
          {
            accessor: 'license_num',
            Header: t('Number of keys / users'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (rowData) => {
              const isUserAuth =
                get(rowData, 'original.authorization_method') === 'user';
              if (isUserAuth) {
                const maxUsers = get(
                  rowData,
                  'original.selected_license_policy.max_license_users',
                );
                const isUnlimited = get(
                  rowData,
                  'original.selected_license_policy.unlimited_max_license_users',
                );
                return isUnlimited || maxUsers === 0
                  ? t('Unlimited')
                  : displayValue(maxUsers);
              }
              return displayValue(rowData.value || 1);
            },
          },
          {
            accessor: 'selected_license_policy.allow_trial',
            Header: t('Is trial'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (cellData) =>
              cellData.value ? (
                <IconCheckmark color="#10ac84" height="14px" />
              ) : (
                <IconDisabled color="#aaa" height="14px" />
              ),
            width: 100,
          },
          {
            Header: t('Duplicate'),
            headerClassName: 'text-center',
            className: 'text-center',
            Cell: (cellData) => (
              <button
                className="edit-button"
                onClick={() => handleProductDuplicate(cellData.original)}
                type="button"
              >
                <IconDuplicate height="20px" width="20px" />
              </button>
            ),
            width: 90,
            show: showActionBtns,
          },
          {
            className: 'text-center',
            Cell: (cellData) => (
              <button
                className="edit-button"
                onClick={() => handleProductEdit(cellData.index)}
                type="button"
              >
                <IconEdit height="20px" width="20px" />
              </button>
            ),
            width: 50,
            show: showActionBtns,
          },
          {
            className: 'text-center',
            Cell: (cellData) => (
              <button
                className="delete-button"
                onClick={() => deleteProductFromList(cellData.index)}
                type="button"
              >
                <IconDelete height="20px" width="20px" color="#ee5253" />
              </button>
            ),
            width: 50,
            show: showActionBtns,
          },
        ]}
        showPagination={false}
        minRows={2}
        noDataText={t('Add products to the list')}
      />
    </div>
  );
};

SelectedProductsTable.propTypes = {
  deleteProductFromList: PropTypes.func,
  handleProductEdit: PropTypes.func,
  handleProductDuplicate: PropTypes.func,
  selectedProducts: PropTypes.array,
  showActionBtns: PropTypes.bool,
};

SelectedProductsTable.defaultProps = {
  deleteProductFromList: () => {},
  handleProductDuplicate: () => {},
  handleProductEdit: () => {},
  selectedProducts: [],
  showActionBtns: true,
};

export default SelectedProductsTable;
