import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';
import { getLicenses } from 'redux/licenses/actions';
import { List, Page, StatusCard, SearchForm } from 'shared/components';
import {
  displayValue,
  formatDate,
  getLicenseStatus,
  transformSort,
} from 'shared/helpers';
import { searchType } from 'shared/constants';

const UserLicenseList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const licenses = useSelector((state) => get(state, 'licenses.list.results'));
  const loading = useSelector((state) => get(state, 'licenses.loading'));
  const pages = useSelector((state) =>
    Math.ceil(get(state, 'licenses.list.count') / 20)
  );

  const [activeQuery, setActiveQuery] = useState('');
  const [page, setPage] = useState(0);
  const [searchBy, setSearchBy] = useState(searchType.productName);
  const [currentSort, setCurrentSort] = useState([
    { desc: true, id: 'created_at' },
  ]);

  useEffect(() => {
    dispatch(
      getLicenses(page, activeQuery, searchBy, transformSort(currentSort))
    );
  }, [page, activeQuery, currentSort, dispatch, searchBy]);

  const redirectToLicensePage = (rowData) => {
    const licenseId = get(rowData, 'original.id');
    history.push(`/licenses/${licenseId}`);
  };

  const handlePageChange = (newPage) => setPage(newPage);
  const handleSortChange = (newSorted) => setCurrentSort(newSorted);
  const handleClear = () => setActiveQuery('');

  const handleSearchSubmit = (val) => {
    setActiveQuery(val);
    setPage(0);
  };

  return (
    <Page
      title={t('Licenses')}
      breadcrumb={<NavLink to="/">{t('Home')}</NavLink>}
    >
      <div className="UserLicenseList">
        <div className="list-header">
          <SearchForm
            options={[
              {
                label: t('Search by product name'),
                value: searchType.productName,
              },
              {
                label: t('Search by license key'),
                value: searchType.licenseKey,
              },
              {
                label: t('Search by order id'),
                value: searchType.orderID,
              },
              {
                label: t('Search by device hostname'),
                value: searchType.hostname,
              },
            ]}
            selectedValue={searchBy}
            handleSearchTypeChange={(newSearchType) =>
              setSearchBy(newSearchType)
            }
            handleSearchSubmit={handleSearchSubmit}
            activeQuery={activeQuery}
            handleClear={handleClear}
            disabled={loading}
          />
          <div />
        </div>
        <List
          data={licenses || []}
          columns={[
            {
              accessor: 'created_at',
              Header: t('Created on'),
              Cell: (cellInfo) => formatDate(cellInfo.value),
            },
            {
              accessor: 'product.product_name',
              Header: t('Product'),
            },
            {
              accessor: 'license_type',
              Header: t('License Type'),
              Cell: (rowData) => {
                const isBundle = get(rowData, 'original.is_bundle');
                if (isBundle) return '-';
                return displayValue(t(rowData.value));
              },
              width: 110,
            },
            {
              accessor: 'time_activated',
              Header: t('Activated on'),
              Cell: (rowData) => {
                const isBundle = get(rowData, 'original.is_bundle');
                if (isBundle) return '-';
                return formatDate(rowData.value);
              },
              width: 140,
            },
            {
              accessor: 'times_activated',
              className: 'text-center',
              headerClassName: 'text-center',
              Header: t('Times activated'),
              Cell: (rowData) => {
                const isBundle = get(rowData, 'original.is_bundle');
                if (isBundle) return '-';
                return displayValue(rowData.value, 0);
              },
              width: 130,
            },
            {
              accessor: 'max_license_users',
              className: 'text-center',
              headerClassName: 'text-center',
              Header: t('Max Users'),
              Cell: (rowData) => {
                const isBundle = get(rowData, 'original.is_bundle');
                if (isBundle) return '-';
                return displayValue(rowData.value, 0);
              },
              width: 130,
            },
            {
              accessor: 'maintenance_period',
              Header: t('Maintenance End Date'),
              Cell: (rowData) => {
                const isBundle = get(rowData, 'original.is_bundle');
                if (isBundle) return '-';
                return displayValue(rowData.value);
              },
            },
            {
              accessor: 'enabled',
              className: 'text-center',
              Header: t('Status'),
              headerClassName: 'text-center',
              Cell: (rowData) => {
                const isBundle = get(rowData, 'original.is_bundle');
                if (isBundle) return '-';
                const status = getLicenseStatus(
                  get(rowData, 'original.enabled'),
                  get(rowData, 'original.active')
                );
                return (
                  <StatusCard
                    status={get(status, 'positive') ? 'success' : 'error'}
                    text={get(status, 'message')}
                  />
                );
              },
              width: 90,
            },
          ]}
          clickable
          page={page}
          pages={pages}
          loading={loading}
          manual
          minRows={get(licenses, 'length') || 10}
          showPagination={pages > 1}
          onPageChange={handlePageChange}
          onSortedChange={handleSortChange}
          defaultSorted={currentSort}
          handleClick={(rowData) => redirectToLicensePage(rowData)}
        />
      </div>
    </Page>
  );
};

export default UserLicenseList;
