import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  DirtyFormAlert,
  Label,
  InputErrorMessage,
  Modal,
  MultiEmailInput,
  Notification,
} from 'shared/components';
import { errorMsg } from 'shared/constants';
import { assignLicenseUsers } from './actions';
import './styles.scss';

const BatchLicenseAssign = ({ closeCb, confirmCb, license }) => {
  const { t } = useTranslation();
  const licenseID = get(license, 'id');
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [licenseUsers, setLicenseUsers] = useState([]);

  const maxLicenseUsers = get(license, 'max_license_users');
  const users = get(license, 'license_users') || [];
  const currentLicenseUsersCount = users.length;
  const isUnlimitedLicenseUsers = get(license, 'max_license_users') === 0;
  const unassignedLicenseCount = isUnlimitedLicenseUsers
    ? 0
    : Number(maxLicenseUsers) - currentLicenseUsersCount;
  const maxLicenseUserStatus = `${currentLicenseUsersCount} / ${isUnlimitedLicenseUsers ? t('Unlimited') : maxLicenseUsers}`;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (licenseUsers.value) {
      setLicenseUsers({
        ...licenseUsers,
        validationError: `${get(errorMsg, 'unsubmittedEmail')} (${get(licenseUsers, 'value')})`,
      });
      return false;
    }

    if (!get(licenseUsers, 'emails.length')) {
      return false;
    }

    if (
      !isUnlimitedLicenseUsers &&
      unassignedLicenseCount < get(licenseUsers, 'emails.length')
    ) {
      return Notification(
        'error',
        t('Check license users count.'),
        t(
          'Number of submitted license user emails is bigger than unassigned licenses count.',
        ),
      );
    }

    setLoading(true);
    const emails = get(licenseUsers, 'emails');
    const userEmailsList = emails.map((em) => ({
      email: em,
      is_manager: false,
    }));
    const data = { emails: userEmailsList };

    assignLicenseUsers(licenseID, data)
      .then(() => {
        confirmCb();
        Notification(
          'success',
          t('Changes saved successfully'),
          t('License users have been assigned'),
        );
        closeCb();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          t('Your changes were not saved'),
          t('There was an error while saving your changes'),
        );
      });

    return true;
  };

  const handleEmailSubmit = (val) => {
    setDirty(true);
    setLicenseUsers(val);
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return setDirtyFormAlertDisplay(true);
  };

  return (
    <Modal
      title={t('Batch license assign')}
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      size="lg"
    >
      <div className="BatchLicenseAssign">
        <div className="BatchLicenseAssign-main">
          <div className="unassigned-count">
            {`${t('Available number of users')}:`}
            &nbsp;
            <span>{maxLicenseUserStatus}</span>
          </div>
          <div>
            {unassignedLicenseCount !== 0 || isUnlimitedLicenseUsers ? (
              <>
                <Label text={t('User emails')} inputId="emails-input" />
                <MultiEmailInput
                  emails={get(licenseUsers, 'emails')}
                  onEmailSubmit={(val) => handleEmailSubmit(val)}
                  disabled={loading}
                />
                <InputErrorMessage
                  text={get(licenseUsers, 'validationError') || ''}
                />
              </>
            ) : (
              <div className="warning-notice">{`${t('All license users are assigned.')}`}</div>
            )}
          </div>
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

BatchLicenseAssign.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  license: PropTypes.object.isRequired,
};

export default BatchLicenseAssign;
