import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const DescriptionTable = ({ details }) => (
  <div className="DescriptionTable">
    <table>
      <tbody>
        {details
          .filter((d) => d.label)
          .map((d) => (
            <tr key={d.label}>
              <td>{d.label}</td>
              <td>{d.value}</td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

DescriptionTable.propTypes = {
  details: PropTypes.array.isRequired,
};

export default DescriptionTable;
