import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const InputErrorMessage = ({ text }) => (
  <div className="InputErrorMessage">{text}</div>
);

InputErrorMessage.propTypes = {
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

InputErrorMessage.defaultProps = {
  text: '',
};

export default InputErrorMessage;
