/* eslint-disable comma-dangle */
import { WebStorageStateStore } from 'oidc-client-ts';

const getAppConfigVar = (varName, fallback) => {
  const env = process.env.REACT_APP_ENV;
  const { appConfig } = window;

  if (!appConfig && env === 'localhost') {
    return fallback;
  }

  const variable = appConfig ? appConfig[varName] : null;

  if (!variable && env === 'localhost') {
    return fallback;
  }

  return variable || '';
};

const getRedirectUri = () => {
  const { origin } = window.location;
  return origin;
};

export const oidcConfig = {
  // authority: getAppConfigVar('keycloakAuthority', 'http://localhost:8081/realms/user-portal'),
  authority: getAppConfigVar(
    'keycloakAuthority',
    'https://auth.testing.licensespring.com/realms/user-portal'
  ),
  client_id: getAppConfigVar('keycloakClientId', 'user-portal'),
  // redirect_uri: getAppConfigVar('keycloakRedirectUri', 'http://localhost:9000'),
  redirect_uri: getRedirectUri(),
  // post_logout_redirect_uri: getAppConfigVar(
  //   'keycloakLogoutRedirectUri',
  //   'http://localhost:9000',
  // ),
  post_logout_redirect_uri: getRedirectUri(),
  onSigninCallback: (user) => {
    if (user) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
};
