import React from 'react';
import get from 'lodash.get';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getUserFromStorage } from 'keycloak/authUser';
import { DescriptionTable, Page, List } from 'shared/components';
import {
  isUserManager,
  hasPermission,
  getTotalLicenseIssued,
} from 'shared/helpers';
import './styles.scss';

const Dashboard = () => {
  const { t } = useTranslation();
  const user = getUserFromStorage();
  const userDetails = useSelector((state) => get(state, 'user.details') || {});
  const permissionsList = useSelector(
    (state) => get(state, 'user.details.permissions') || [],
  );
  const counter = useSelector((state) => get(state, 'user.counter') || []);
  const canIssueLicenses = hasPermission(permissionsList, 'issue_license');
  const canCreateOrder = hasPermission(permissionsList, 'create_order');

  const distributionPermissions = canIssueLicenses || canCreateOrder;

  const displayUserName = (name) =>
    name ? (
      <div>
        {`${t('Welcome')}, `}
        <NavLink to="/profile">{get(user, 'profile.name')}</NavLink>
      </div>
    ) : (
      <div>{t('Welcome')}</div>
    );

  const isManager = isUserManager(userDetails);

  return (
    <div className="Dashboard">
      <Page
        title={t('Dashboard')}
        breadcrumb={displayUserName(get(user, 'profile.name'))}
      >
        <div className="Dashboard-inner">
          <DescriptionTable
            details={[
              {
                label: t('Role'),
                value: isManager
                  ? `${t('License manager')}${distributionPermissions ? `| ${t('Distributor')}` : ''}`
                  : t('License user'),
              },
              {
                label: distributionPermissions ? null : t('Number of licenses'),
                value: get(userDetails, 'number_of_licenses') || 0,
              },
              {
                label: distributionPermissions
                  ? t('Total licenses issued')
                  : null,
                value: getTotalLicenseIssued(counter),
              },
            ]}
          />
          {distributionPermissions && (
            <List
              columns={[
                { accessor: 'product.product_name', Header: t('Product name') },
                {
                  accessor: 'created_licenses_count',
                  Header: t('Issued licenses'),
                },
                {
                  accessor: 'num_of_licenses',
                  Header: t('Remaining licenses'),
                  Cell: (rowData) => {
                    const { value } = rowData;
                    if (value === null) {
                      return t('Unlimited');
                    }
                    const used = get(
                      rowData,
                      'original.created_licenses_count',
                    );
                    const remaining = value - used;
                    return remaining;
                  },
                },
              ]}
              data={counter}
              minRows={2}
              pageSize={20}
              showPagination={false}
            />
          )}
        </div>
      </Page>
    </div>
  );
};

export default Dashboard;
