/* eslint-disable no-useless-escape */
export const defaultDateTimeFormat = 'DD/MM/YYYY HH:mm';

export const APIdateFormat = 'YYYY-MM-DD';

export const validityPeriodTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export const defaultTimeFormat = 'HH:mm';

export const defaultDateFormat = 'DD/MM/YYYY';

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const urlRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const numberRegex = /\d+/g;
export const lettersRegex = /[a-zA-Z]+/g;
export const versionRegex = /^v?\d+(\.\d+){0,2}$/;

export const validationTimeDefault = 300;
export const validationTimeLong = 1000;

export const errorMsg = {
  alphanumeric: 'Use lowercase characters and numbers only',
  consumptionBiggerThanZero:
    'Selected consumption values have to be bigger than zero',
  customerRequiredAssignManager:
    'Select customer if you want to assign it as license manager',
  cfsInvalidValue: 'Custom field is missing a value',
  codeDuplicate: 'This code is already in use',
  codeMaxChars: 'Product code can have max 20 characters',
  companyDuplicate: 'This company name is already in use',
  dateYYYYMMDDformat: 'Please enter date in YYYY-MM-DD format',
  emailDuplicate: 'This email is already in use',
  globalError: 'There was an error, please try again later',
  longHash: "File hash  can't have more than 32 characters",
  invalidData: 'Enter valid data',
  invalidEmail: 'Enter valid email',
  invalidEmails: 'Enter valid emails',
  invalidJson: 'Invalid JSON',
  invalidJsonObject: 'Invalid JSON object',
  invalidUrl: 'Enter valid URL',
  invalidVersionFormat:
    'Version should consist of max 3 numbers separated with a dot and optional `v` in front - ex: 1.2.3, v1.2, 0.0.1',
  maxActivations:
    'Max activations number cannot be lower than max license users',
  maxActivationsDevices:
    'Number of max activations is less than the number of currently active devices – please deactivate one or more devices before decreasing the number of activations.',
  maxChars: 'Max allowed characters count for this field:',
  maxSimultaneousUsers:
    'Max simultaneous license users number cannot be higher than max activations',
  maxQuantity: 'Max allowed quantity is: ',
  nameDuplicate: 'This product name is already in use',
  nameDuplicateGeneric: 'This name is already in use',
  nameArchivedDuplicate:
    'Product with this is name is archived. Change the name or activate archived product.',
  notAssigned: 'Not assigned',
  passCharNum: 'Password should contain at least 8 characters',
  passCurrent: 'Enter your current password',
  passLowercase: 'Password should contain at least 1 lowercase letter',
  passMatch: 'Passwords do not match',
  passNumber: 'Password should contain at least 1 number',
  passUppercase: 'Password should contain at least 1 uppercase letter',
  remainingLicenses: 'Remaining licenses to issue: ',
  required: 'This field is required',
  requiredFile: 'This file is required',
  selectCustomer: 'Select customer from the dropdown',
  selectErr: 'Select from dropdown',
  tooManyEmails:
    'You have entered too many emails, check your max license users number input',
  unsubmittedEmail:
    'In order to submit a user email, please add the email address of your desired user and press enter',
  userDuplicate: 'A user is already a member of this company.',
};

export const searchType = {
  licenseUserEmail: 'license_users__true_email__icontains',
  licenseKey: 'license_key__icontains',
  order_storeID: 'store_id__icontains',
  orderID: 'order__store_id__icontains',
  productName: 'product_name__icontains',
  hostname: 'devices__hostname__icontains',
};

// export const searchType = {
//   license: 'license',
//   order: 'store_id',
//   user: 'customer__email',
//   email: 'email',
//   licenseNumber: 'license_key',
//   licenseUserEmail: 'license_user.true_email',
//   customerCompany: 'customer_company_name',
//   customerReference: 'customer_reference',
//   customerName: 'customer_name',
//   firstName: 'first_name',
//   lastName: 'last_name',
//   phoneNumber: 'phone',
//   companyName: 'company_name',
//   productName: 'product_name',
//   productCode: 'short_code',
// };

export const licenseUsageTypes = [
  {
    value: 'all',
    label: 'All licenses',
  },
  {
    value: 'node_locked_key',
    label: 'Node locked key auth licenses',
  },
  {
    value: 'node_locked_user',
    label: 'Node locked user auth licenses',
  },
  {
    value: 'floating_key',
    label: 'Floating key auth licenses',
  },
  {
    value: 'floating_user',
    label: 'Floating user auth licenses',
  },
];

export const initialPortalData = {
  topHeaderBackground: '#555555',
  sidebarBackground: '#ffffff',
  pageBackground: '#fafafa',
  showProductLinks: false,
  allowUsersLicenseReset: true,
  allowUsersLicenseBorrow: false,
  loginTitle: '',
  loginMessage: '',
};

export const environmentOptions = [
  { label: 'Linux 32/64', value: 'linux' },
  { label: 'Linux 32', value: 'linux32' },
  { label: 'Linux 64', value: 'linux64' },
  { label: 'Mac OS', value: 'mac' },
  { label: 'Windows 32/64', value: 'win' },
  { label: 'Windows 32', value: 'win32' },
  { label: 'Windows 64', value: 'win64' },
];

export const managerPermissionDescription = {
  create_license_policy: 'Can create new license policies',
  create_order: 'Can create new orders',
  issue_license: 'Can issue new licenses on existing order',
  manage_license_users: 'Can add, edit or delete license users',
  manage_license_user_credentials: 'Can change license users passwords',
  manage_managers: 'Can add new license managers to some order',
  reset_device: 'Can reset device',
  reset_license: 'Can reset license',
  show_license_usage_report:
    'Can see the activity report ( number of created orders and issued licenses )',
};

export const productFeatureTypes = {
  activation: 'activation',
  consumption: 'consumption',
};

export const licenseTypes = {
  perpetual: 'perpetual',
  subscription: 'subscription',
  time_limited: 'time-limited',
  consumption: 'consumption',
};

export const authMethods = {
  key: 'license-key',
  user: 'user',
};
