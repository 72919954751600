import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ConfirmationPopup,
  IconCheckmark,
  IconDisabled,
  List,
  Notification,
  StatusCard,
} from 'shared/components';
import { displayValue, formatDate, hasPermission } from 'shared/helpers';
import { errorMsg } from 'shared/constants';
import { disableLicenses } from 'orders/OrderContainer/actions';
import { mapLicenses } from './helpers';

const LicensesList = ({ order, refetchOrder }) => {
  const { t } = useTranslation();
  const permissionsList = useSelector((state) =>
    get(state, 'user.details.permissions'),
  );
  const history = useHistory();
  const orderLicenses = mapLicenses(get(order, 'order_items') || []);
  const canIssueLicenses = hasPermission(permissionsList, 'issue_license');
  const isBundle = get(order, 'type') === 'bundle';
  const bundleLicenses = orderLicenses.filter((l) => l.is_bundle);

  const [disablingLicenses, setDisablingLicenses] = useState(false);
  const [disableBulkLicensePopupDisplayed, setDisableBulkLicensePopupDisplay] =
    useState(false);

  const redirectToLicensePage = (rowData) => {
    const licenseId = get(rowData, 'original.id');
    history.push(`/licenses/${licenseId}`);
  };

  const handleLicensesDisable = () => {
    const licenseIds = orderLicenses.map((o) => o.id);
    setDisablingLicenses(true);

    disableLicenses(licenseIds)
      .then(() => {
        refetchOrder();
        setDisablingLicenses(false);
        setDisableBulkLicensePopupDisplay(false);
        Notification('success', t('Licenses succesfully disabled'));
      })
      .catch(() => {
        setDisablingLicenses(false);
        Notification(
          'error',
          t('Error occured'),
          t('Your changes were not saved'),
        );
      });
  };

  const handleBulkLicenseDisableClick = () => {
    const permission = hasPermission(permissionsList, 'reset_license');
    if (!permission) {
      Notification(
        'error',
        t('Missing permission'),
        t('You do not have permission to perform this action.'),
      );
      return false;
    }
    setDisableBulkLicensePopupDisplay(true);
    return true;
  };

  const handleNewOrder = () => {
    if (!canIssueLicenses) {
      Notification(
        'error',
        t('Missing permission'),
        t('You do not have permission to perform this action.'),
      );
      return false;
    }
    history.push(`/create-order/${get(order, 'id')}`);
    return true;
  };

  return (
    <div className="LicensesList licenses-table">
      <div className="list-header">
        <div>
          {canIssueLicenses && (
            <Button onClick={handleNewOrder} theme="info" size="sm">
              {t('Issue new Licenses')}
            </Button>
          )}
        </div>
        <div>
          <Button
            theme="error"
            size="sm"
            onClick={handleBulkLicenseDisableClick}
          >
            {t('Disable all licenses')}
          </Button>
        </div>
      </div>
      <List
        clickable
        columns={[
          {
            id: 'license_key',
            Header: `${t('License key')}/${t('User')}`,
            width: 250,
            sortable: false,
            Cell: (rowData) => {
              const licenseUsers = get(rowData, 'original.license_users') || [];
              const usersNum = licenseUsers.length;
              const licenseKey = get(rowData, 'original.license_key');
              if (usersNum > 0) {
                const firstUser = get(licenseUsers, '[0].true_email');
                const isSingleUser = usersNum === 1;
                if (isSingleUser) {
                  return `${firstUser}`;
                }
                return `${firstUser} (+${usersNum - 1})`;
              }
              if (licenseKey) {
                return displayValue(licenseKey);
              }
              return t(errorMsg.notAssigned);
            },
          },
          {
            accessor: 'license_type',
            Header: t('License Type'),
            Cell: (rowData) => displayValue(t(rowData.value)),
            show: !isBundle,
          },
          {
            accessor: 'validity_period',
            Header: t('Expiration Date'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (rowData) => displayValue(rowData.value),
            show: !isBundle,
          },
          {
            accessor: 'product',
            Header: t('Product'),
            width: 150,
          },
          {
            accessor: 'created_at',
            Header: t('Created on'),
            Cell: (rowData) => formatDate(rowData.value),
          },
          {
            accessor: 'status',
            className: 'text-center',
            Header: t('Status'),
            headerClassName: 'text-center',
            Cell: (cellInfo) => (
              <StatusCard
                status={cellInfo.value === 'Active' ? 'success' : 'error'}
                text={t(cellInfo.value)}
              />
            ),
            show: !isBundle,
          },
          {
            accessor: 'is_trial',
            Header: t('Is trial'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (rowData) =>
              rowData.value ? (
                <IconCheckmark color="#10ac84" height="14px" />
              ) : (
                <IconDisabled color="#aaa" height="14px" />
              ),
            show: !isBundle,
          },
          {
            accessor: 'is_bundle',
            Header: t('Is bundle'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (cellData) =>
              cellData.value ? (
                <IconCheckmark color="#10ac84" height="14px" />
              ) : (
                <IconDisabled color="#aaa" height="14px" />
              ),
            width: 100,
            show: isBundle,
          },
        ]}
        data={isBundle ? bundleLicenses : orderLicenses}
        handleClick={(rowData) => redirectToLicensePage(rowData)}
        minRows={1}
        pageSize={20}
        sortable={false}
      />
      {disableBulkLicensePopupDisplayed && (
        <ConfirmationPopup
          closeCb={() => setDisableBulkLicensePopupDisplay(false)}
          confirmCb={handleLicensesDisable}
          confirmText={t('Disable')}
          disabled={disablingLicenses}
          theme="error"
          title={t(
            'Are you sure you want to disable all licenses from this order?',
          )}
        />
      )}
    </div>
  );
};

LicensesList.propTypes = {
  order: PropTypes.object.isRequired,
  refetchOrder: PropTypes.func.isRequired,
};

export default LicensesList;
