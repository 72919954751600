import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { getOrders } from 'redux/orders/actions';
import {
  IconCheckmark,
  IconDisabled,
  List,
  Page,
  SearchForm,
} from 'shared/components';
import {
  displayValue,
  formatDate,
  getTableCustomerName,
  transformSort,
} from 'shared/helpers';
import { searchType } from 'shared/constants';

const OrdersList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const orders = useSelector((state) => get(state, 'orders.list.results'));
  const loading = useSelector((state) => get(state, 'orders.loading'));
  const pages = useSelector((state) =>
    Math.ceil(get(state, 'orders.list.count') / 20),
  );

  const [activeQuery, setActiveQuery] = useState('');
  const [page, setPage] = useState(0);
  const [searchBy, setSearchBy] = useState(searchType.order_storeID);
  const [currentSort, setCurrentSort] = useState([
    { desc: true, id: 'created_at' },
  ]);

  useEffect(() => {
    dispatch(
      getOrders(page, activeQuery, searchBy, transformSort(currentSort)),
    );
  }, [page, activeQuery, currentSort, dispatch, searchBy]);

  const redirectToOrderPage = (rowData) => {
    const orderId = get(rowData, 'original.id');
    history.push(`/orders/${orderId}`);
  };

  const handlePageChange = (newPage) => setPage(newPage);
  const handleSortChange = (newSorted) => setCurrentSort(newSorted);
  const handleClear = () => setActiveQuery('');

  const handleSearchSubmit = (val) => {
    setActiveQuery(val);
    setPage(0);
  };

  return (
    <Page
      title={t('Orders')}
      breadcrumb={<NavLink to="/">{t('Home')}</NavLink>}
    >
      <div className="OrdersList">
        <div className="list-header">
          <SearchForm
            options={[
              {
                label: t('Search by order id'),
                value: searchType.order_storeID,
              },
            ]}
            selectedValue={searchBy}
            handleSearchTypeChange={(newSearchType) =>
              setSearchBy(newSearchType)
            }
            handleSearchSubmit={handleSearchSubmit}
            activeQuery={activeQuery}
            handleClear={handleClear}
            disabled={loading}
          />
          <div />
        </div>
        <List
          data={orders || []}
          columns={[
            {
              accessor: 'created_at',
              Header: t('Created on'),
              Cell: (cellInfo) => formatDate(cellInfo.value),
              width: 180,
            },
            {
              accessor: 'store_id',
              Header: t('Order ID'),
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: 'customer.email',
              Header: t('Customer email'),
              Cell: (cellData) => displayValue(cellData.value),
              maxWidth: 400,
            },
            {
              accessor: 'customer.last_name',
              Header: t('Customer name'),
              Cell: (rowData) => getTableCustomerName(rowData.original),
            },
            {
              accessor: 'customer.company_name',
              Header: t('Customer company'),
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: 'updated_at',
              Header: t('Last updated'),
              Cell: (cellInfo) => formatDate(cellInfo.value),
            },
            {
              accessor: 'type',
              Header: t('Type'),
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: 'is_trial',
              Header: t('Is trial'),
              headerClassName: 'text-center',
              className: 'text-center',
              Cell: (cellData) =>
                cellData.value ? (
                  <IconCheckmark color="#10ac84" height="14px" />
                ) : (
                  <IconDisabled color="#aaa" height="14px" />
                ),
              width: 80,
            },
          ]}
          clickable
          page={page}
          pages={pages}
          loading={loading}
          manual
          minRows={get(orders, 'length') || 10}
          showPagination={pages > 1}
          onPageChange={handlePageChange}
          onSortedChange={handleSortChange}
          defaultSorted={currentSort}
          handleClick={(rowData) => redirectToOrderPage(rowData)}
        />
      </div>
    </Page>
  );
};

export default OrdersList;
