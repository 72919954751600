import get from 'lodash.get';
import api from 'shared/api';
import * as actionTypes from './actionTypes';

// user actions
const initGetUser = (actions) => () => ({
  type: actions.getUserInit,
});

const getUserError = (actions) => () => ({
  type: actions.getUserError,
});

const getUserSuccess = (actions) => (res) => ({
  type: actions.getUserSuccess,
  user: res,
});

export const getUser = (userID) => (dispatch) => {
  dispatch(initGetUser(actionTypes)());
  return api.get(`/api/v1/users/${userID}`).then(
    (res) => dispatch(getUserSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getUserError(actionTypes)()),
  );
};

export const refreshUser = (res) => ({
  type: actionTypes.refreshUser,
  user: res,
});

// login actions
const initLogin = (actions) => () => ({
  type: actions.loginInit,
});

export const loginSuccess = (res) => (dispatch) =>
  dispatch({
    type: actionTypes.loginSuccess,
    user: res,
  });

export const loginError = (res) => (dispatch) =>
  dispatch({
    type: actionTypes.loginError,
    payload: res,
  });

export const login = (loginData) => (dispatch) => {
  dispatch(initLogin(actionTypes)());
  return api.post('/api/v1/auth/login/', loginData);
};

// logout actions
export const logoutSuccess = () => (dispatch) =>
  dispatch({
    type: actionTypes.logoutSuccess,
    user: null,
  });

export const logoutError = () => (dispatch) =>
  dispatch({
    type: actionTypes.logoutError,
  });

export const logout = () => (dispatch) => {
  dispatch({ type: actionTypes.logoutInit });
  return api.get('/api/v1/auth/logout/');
};

// custom domain
export const setCustomDomainData = (data) => (dispatch) =>
  dispatch({
    type: actionTypes.setCustomDomainData,
    data,
  });

// manager permissions
const getManagerPermissionsSuccess = (managerPerm) => ({
  type: actionTypes.getManagerPermissionsSuccess,
  payload: managerPerm,
});

const getManagerPermissionsError = (error) => ({
  type: actionTypes.getManagerPermissionsError,
  payload: error,
});

export const getManagerPermissions = () => (dispatch) => {
  dispatch({ type: actionTypes.getManagerPermissionsInit });
  return api.get('/api/v1/license-manager-permissions/').then(
    (res) => dispatch(getManagerPermissionsSuccess(get(res, 'data.results'))),
    (err) => dispatch(getManagerPermissionsError(err)),
  );
};

// counter
const getManagerCounterSuccess = (managerCounter) => ({
  type: actionTypes.getManagerCounterSuccess,
  payload: managerCounter,
});

const getManagerCounterError = (error) => ({
  type: actionTypes.getManagerCounterError,
  payload: error,
});

export const getManagerCounter = (userID) => (dispatch) => {
  dispatch({ type: actionTypes.getManagerCounterInit });
  return api.get(`/api/v1/users/${userID}/created_licenses_counts/`).then(
    (res) =>
      dispatch(
        getManagerCounterSuccess(get(res, 'data.created_licenses_counts')),
      ),
    (err) => dispatch(getManagerCounterError(err)),
  );
};
