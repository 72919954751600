import api from 'shared/api';
import { formatOrderBy } from 'shared/helpers';
import * as actionTypes from './actionTypes';

const getOrdersInit = () => ({
  type: actionTypes.getOrdersInit,
});

const getOrdersSuccess = (res) => ({
  type: actionTypes.getOrdersSuccess,
  orders: res,
});

const getOrdersError = (res) => ({
  type: actionTypes.getOrdersError,
  orders: res,
});

export const getOrders =
  (page, query, queryType, sortBy, filters = '') =>
  (dispatch) => {
    dispatch(getOrdersInit());
    const queryUrl = query ? `&${queryType}=${query}` : '';
    const sortUrl = formatOrderBy(sortBy);
    const url = `/api/v1/orders/?limit=20&offset=${page ? page * 20 : 0}${queryUrl}${sortUrl}${filters}`;
    return api.get(url).then(
      (res) => dispatch(getOrdersSuccess(res.data)),
      (res) => dispatch(getOrdersError(res.data)),
    );
  };
