import React from 'react';
import PropTypes from 'prop-types';
import withClose from '../hoc/withClose';
import './styles.scss';

const Dropdown = ({ children, forwardedRef }) => (
  <div className="Dropdown" ref={forwardedRef}>
    {children}
  </div>
);

Dropdown.propTypes = {
  children: PropTypes.element.isRequired,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Dropdown) }),
    PropTypes.element,
    PropTypes.object,
  ]).isRequired,
};

export default withClose(Dropdown);
