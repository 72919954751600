import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
  NumberInput,
} from 'shared/components';
import {
  validateRequiredNumber,
  debouncedValidateRequiredNumber,
} from 'shared/validation';
import { setLicenseUserActivations } from '../../LicenseContainer/LicenseUsers/actions';
import './styles.scss';

const LicenseUserMaxActivations = ({
  closeCb,
  user,
  licenseID,
  refetchLicense,
}) => {
  const { t } = useTranslation();
  const userID = get(user, 'id');
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);
  // form state
  const [maxActivations, setMaxActivations] = useState(
    get(user, 'max_activations') || 1,
  );
  const [maxActivationsError, setMaxActivationsError] = useState('');

  const validateMaxActivations = async (val) => {
    setLoading(true);
    let errors;

    try {
      errors = await validateRequiredNumber(val);
      setLoading(false);
      setMaxActivationsError(errors);
    } catch (err) {
      setLoading(false);
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const handleMaxActivationsChange = (val) => {
    setDirty(true);
    setMaxActivations(val);
    debouncedValidateRequiredNumber(val).then((err) =>
      setMaxActivationsError(err),
    );
  };

  const isFormValid = async () => {
    const areMaxActivationsValid = await validateMaxActivations(maxActivations);
    return areMaxActivationsValid;
  };

  const setTotalActivations = () => {
    setLoading(true);
    const data = {
      [userID]: { max_activations: maxActivations },
    };
    setLicenseUserActivations(licenseID, data)
      .then(() => {
        refetchLicense();
        closeCb();
        Notification('success', t('Changes saved successfully'), '');
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          t('Your changes were not saved'),
          t('There was an error while saving your changes'),
        );
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setDirty(false);
    setLoading(true);
    setTotalActivations();
    return true;
  };

  const handleClose = () => {
    if (!isDirty) {
      return closeCb();
    }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      size="sm"
      title={t('Set max activations')}
    >
      <form className="LicenseUserMaxActivations" onSubmit={handleSubmit}>
        <div className="form-inner">
          <div className="form-row">
            <Label text={t('Email')} inputId="email" />
            <TextInput
              id="email"
              value={get(user, 'true_email') || ''}
              disabled
              handleChange={() => {}}
            />
          </div>
          <div className="form-row">
            <Label
              text={t('Max activations')}
              inputId="max-activations-input"
            />
            <NumberInput
              handleChange={handleMaxActivationsChange}
              value={maxActivations}
              error={maxActivationsError}
              disabled={isLoading}
              min="1"
              max="1000"
              id="max-activations-input"
            />
          </div>
        </div>
        <button type="submit" style={{ visibility: 'hidden' }} />
      </form>
      {isDirtyFormDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

LicenseUserMaxActivations.propTypes = {
  closeCb: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  licenseID: PropTypes.number.isRequired,
  refetchLicense: PropTypes.func.isRequired,
};

export default LicenseUserMaxActivations;
