import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GlobalError } from 'shared/components';

class ErrorBoundary extends Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(err) {
    // Update state so the next render will show the fallback UI.
    return { hasError: !!err };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <GlobalError />;
    }

    return <>{children}</>;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
};

export default ErrorBoundary;
