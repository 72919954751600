import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  InputErrorMessage,
  Notification,
  Switcher,
  Label,
} from 'shared/components';
import { hasPermission } from 'shared/helpers';
import SelectProductForm from '../SelectProductForm';
import SelectedProductsTable from '../SelectedProductsTable';
import SelectBundleForm from '../SelectBundleForm';
import SelectedBundlesTable from '../SelectedBundlesTable';
import './styles.scss';

const ProductsStep = ({ companyID, formState, handleBack, handleSubmit }) => {
  const { t } = useTranslation();
  const permissionsList = useSelector((state) =>
    get(state, 'user.details.permissions'),
  );

  const [loading, setLoading] = useState(false);
  const [addProductError, setAddProductError] = useState('');
  const [selectedProducts, setSelectedProducts] = useState(
    get(formState, 'products'),
  );
  const [productToEditData, setProductToEditData] = useState(null);
  const [productToEditIndex, setProductToEditIndex] = useState(null);
  const [isAddProductModalDisplayed, setAddProductModalDisplay] =
    useState(false);

  const [isBundleSelected, setBundleSelected] = useState(
    get(formState, 'is_bundle'),
  );
  const [selectedBundle, setSelectedBundle] = useState(
    get(formState, 'bundle'),
  );
  const [isAddBundleModalDisplayed, setAddBundleModalDisplay] = useState(false);

  const editProductOnList = (product) => {
    const productsList = selectedProducts.map((sp, i) => {
      if (i === productToEditIndex) {
        const newProduct = { ...product };
        return newProduct;
      }
      return sp;
    });

    setSelectedProducts(productsList);
    setProductToEditData(null);
    setProductToEditIndex(null);
    setAddProductModalDisplay(false);
  };

  const deleteProductFromList = (index) => {
    const newProductsList = selectedProducts.filter((_, i) => i !== index);
    setSelectedProducts(newProductsList);
    setProductToEditData(null);
    setProductToEditIndex(null);
  };

  const handleProductDuplicate = (product) => {
    const newList = [...selectedProducts, product];
    setSelectedProducts(newList);
  };

  const handleProductEdit = (index) => {
    const productToEdit = selectedProducts[index];
    setProductToEditData(productToEdit);
    setProductToEditIndex(index);
    setAddProductModalDisplay(true);
  };

  const handleFormSubmit = (product, isEditing) => {
    setAddProductModalDisplay(false);
    setAddProductError('');
    if (!isEditing) {
      setSelectedProducts([...selectedProducts, product]);
    } else {
      editProductOnList(product);
    }
  };

  const handleBundleSubmit = (bundle) => {
    setAddBundleModalDisplay(false);
    setAddProductError('');
    setSelectedBundle(bundle);
  };

  const handleBundleDelete = () => {
    setSelectedBundle(null);
  };

  const handleBackClick = () => handleBack({ products: selectedProducts });

  const handleNextStepClick = () => {
    if (loading) {
      return false;
    }
    setLoading(true);

    if (!isBundleSelected && (!selectedProducts || !selectedProducts.length)) {
      setAddProductError(t('Add products to order'));
      Notification(
        'error',
        t('Please enter correct information and try again'),
      );
      setLoading(false);
      return false;
    }

    if (isBundleSelected && !selectedBundle) {
      setAddProductError(t('Add bundle to order'));
      Notification(
        'error',
        t('Please enter correct information and try again'),
      );
      setLoading(false);
      return false;
    }

    if (isBundleSelected) {
      handleSubmit({ bundle: selectedBundle, is_bundle: true });
      return true;
    }

    handleSubmit({ products: selectedProducts, is_bundle: isBundleSelected });
    return true;
  };

  const allowsProductBundles = hasPermission(
    permissionsList,
    'create_bundle_order',
  );

  return (
    <div className="ProductsStep">
      {allowsProductBundles && (
        <div className="bundle-row">
          <Switcher
            checked={isBundleSelected}
            handleChange={(val) => {
              setSelectedProducts([]);
              setSelectedBundle(null);
              setBundleSelected(val);
            }}
            size="sm"
            id="product-bundle-input"
          />
          <Label
            inputId="product-bundle-input"
            text={t('Create order for product bundle')}
          />
        </div>
      )}
      <div className="ProductsStep-add-product">
        <Button
          theme="success"
          size="sm"
          disabled={isBundleSelected && !!selectedBundle}
          onClick={() => {
            if (isBundleSelected) {
              setAddBundleModalDisplay(true);
            } else {
              setAddProductModalDisplay(true);
            }
          }}
        >
          {isBundleSelected ? t('Select product bundle') : t('Add Product')}
        </Button>
        <InputErrorMessage text={addProductError} />
      </div>
      <div className="ProductsStep-table">
        {isBundleSelected ? (
          <SelectedBundlesTable
            companyID={companyID}
            selectedBundles={selectedBundle ? [selectedBundle] : []}
            deleteBundleFromList={handleBundleDelete}
            handleBundleEdit={() => setAddBundleModalDisplay(true)}
          />
        ) : (
          <SelectedProductsTable
            companyID={companyID}
            deleteProductFromList={deleteProductFromList}
            handleProductEdit={handleProductEdit}
            handleProductDuplicate={handleProductDuplicate}
            selectedProducts={selectedProducts}
          />
        )}
      </div>
      <div className="ProductsStep-actions">
        <Button theme="default" size="lg" onClick={handleBackClick}>
          {t('Back')}
        </Button>
        <Button theme="info" size="lg" onClick={handleNextStepClick}>
          {t('Next')}
        </Button>
      </div>
      {isAddProductModalDisplayed && (
        <SelectProductForm
          isOpen={isAddProductModalDisplayed}
          closeCb={() => {
            setProductToEditData(null);
            setProductToEditIndex(null);
            setAddProductModalDisplay(false);
          }}
          handleSubmit={handleFormSubmit}
          title={t('Select product')}
          companyID={companyID}
          productToEdit={productToEditData}
        />
      )}
      {isAddBundleModalDisplayed && (
        <SelectBundleForm
          isOpen={isAddBundleModalDisplayed}
          closeCb={() => setAddBundleModalDisplay(false)}
          handleSubmit={handleBundleSubmit}
          title={t('Select product bundle')}
          companyID={companyID}
          bundleToEdit={selectedBundle}
        />
      )}
    </div>
  );
};

ProductsStep.propTypes = {
  companyID: PropTypes.number.isRequired,
  formState: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ProductsStep;
