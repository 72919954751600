import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import Dashboard from 'dashboard/Dashboard';
import ProfileContainer from 'profile/ProfileContainer';
import LicenseContainer from 'licenses/LicenseContainer';
import Licenses from 'licenses';
import CreateOrderForm from 'CreateOrderForm';
import Orders from 'orders';
import OrderContainer from 'orders/OrderContainer';
import Header from 'header';
import {
  getManagerPermissions,
  getManagerCounter,
  getUser,
} from 'redux/user/actions';
import { ContentLoader } from 'shared/components';

const AppContainer = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const userID = get(auth, 'user.profile.platform_user_id');

  const userLoading = useSelector((state) => get(state, 'user.loading'));
  const customDomainData = useSelector((state) =>
    get(state, 'user.customDomain'),
  );
  const managerPermissionsLoading = useSelector((state) =>
    get(state, 'user.managerPermissionsLoading'),
  );
  const companyID = get(customDomainData, 'id');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser(userID));
    dispatch(getManagerPermissions(companyID));
    dispatch(getManagerCounter(userID, companyID));
  }, [companyID, dispatch, userID]);

  if (managerPermissionsLoading || userLoading) {
    return <ContentLoader text={t('Getting account details')} />;
  }

  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/licenses" component={Licenses} />
        <Route path="/licenses/:licenseId" component={LicenseContainer} />
        <Route exact path="/orders" component={Orders} />
        <Route path="/orders/:orderId" component={OrderContainer} />
        <Route
          path="/create-order/:orderId"
          exact
          component={CreateOrderForm}
        />
        <Route path="/create-order" component={CreateOrderForm} />
        <Route path="/profile" component={ProfileContainer} />
      </Switch>
    </>
  );
};

export default AppContainer;
