import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash.get';
import { setCustomDomainData } from 'redux/user/actions';
import { ContentLoader } from 'shared/components';
import api from 'shared/api';
import AppContainer from '../AppContainer';

const DomainCheck = () => {
  const env = process.env.REACT_APP_ENV;
  const domain = get(window, 'location.hostname') || '';
  const dispatch = useDispatch();
  const [isDomainChecked, setIsDomainChecked] = useState(false);

  const initialDomainCheck = useCallback(async () => {
    try {
      const company = await api.get(
        `/api/v1/companies/get_id?domain=${domain}`,
      );
      const customDomain = get(company, 'data');
      dispatch(setCustomDomainData(customDomain));
      setIsDomainChecked(true);
    } catch (error) {
      // console.log(error);
      if (env === 'localhost') {
        dispatch(
          setCustomDomainData({
            code: 'tbc',
            id: 10001,
            logo: null,
            name: 'The Boring Company',
            portal_data: '',
          }),
        );
        setIsDomainChecked(true);
      }
      setIsDomainChecked(true);
    }
  }, [dispatch, domain, env]);

  useEffect(() => {
    initialDomainCheck();
  }, [initialDomainCheck]);

  if (!isDomainChecked) {
    return <ContentLoader />;
  }

  return <AppContainer />;
};

export default DomainCheck;
