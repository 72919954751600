import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

const sizes = {
  sm: {
    height: 16,
    width: 36,
  },
  md: {
    height: 28,
    width: 56,
  },
  lg: {
    height: 36,
    width: 72,
  },
};

const Switcher = ({ desc, size, handleChange, ...rest }) => {
  const [checked, setChecked] = useState(false);

  const handleSwitcherChange = (val) => {
    setChecked(val);
    handleChange(val);
    return true;
  };

  return (
    <div className="Switcher">
      <Switch
        aria-label={desc}
        checked={checked}
        onChange={handleSwitcherChange}
        onColor="#10ac84"
        height={sizes[size].height}
        width={sizes[size].width}
        {...rest}
      />
    </div>
  );
};

Switcher.propTypes = {
  desc: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Switcher.defaultProps = {
  desc: 'switcher label',
  size: 'md',
};

export default Switcher;
