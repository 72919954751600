import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {
  emailRegex,
  errorMsg,
  validationTimeDefault,
  validationTimeLong,
} from 'shared/constants';
import { isValidDate } from 'shared/helpers';

export const validateRequiredValue = (val) =>
  new Promise((resolve) => {
    let error = '';
    if (!val) {
      error = errorMsg.required;
    }
    resolve(error);
  });

export const debouncedValidateRequiredValue = AwesomeDebouncePromise(
  validateRequiredValue,
  validationTimeDefault,
);

export const validateRequiredNumber = (val, includeZero = false) =>
  new Promise((resolve) => {
    let error = '';
    if (includeZero) {
      // value can be set to 0
      if (val === '0' || val === 0) {
        resolve(error);
      }
    }

    if (!val || Number(val) <= 0) {
      error = errorMsg.required;
    }
    resolve(error);
  });

export const debouncedValidateRequiredNumber = AwesomeDebouncePromise(
  validateRequiredNumber,
  validationTimeDefault,
);

export const validateEmail = (val) =>
  new Promise((resolve) => {
    let error = '';
    if (!val) {
      error = errorMsg.required;
      resolve(error);
    }

    if (!emailRegex.test(val)) {
      error = errorMsg.invalidEmail;
      resolve(error);
    }
    resolve(error);
  });

export const debouncedValidateEmail = AwesomeDebouncePromise(
  validateEmail,
  validationTimeDefault,
);

export const validateDate = (val) =>
  new Promise((resolve) => {
    let error = '';
    if (!val) {
      error = errorMsg.required;
      resolve(error);
    }
    if (!isValidDate(val)) {
      error = errorMsg.dateYYYYMMDDformat;
      resolve(error);
    }
    resolve(error);
  });

export const debouncedValidateDate = AwesomeDebouncePromise(
  validateDate,
  validationTimeDefault,
);

export const validateExistingUserEmail = (val) =>
  new Promise((resolve) => {
    let error = '';

    if (!val) {
      error = errorMsg.selectErr;
      resolve(error);
    }

    if (!emailRegex.test(val)) {
      error = errorMsg.invalidEmail;
      resolve(error);
    }

    resolve(error);
  });

export const validateLicenseUsers = (emails = [], num) =>
  new Promise((resolve) => {
    let error = '';

    if (!emails || !emails.length) {
      resolve(error);
    }

    if (emails.length > Number(num)) {
      error = errorMsg.tooManyEmails;
      resolve(error);
    }

    resolve(error);
  });

export const debouncedValidateLicenseUsers = AwesomeDebouncePromise(
  validateLicenseUsers,
  validationTimeDefault,
);

export const validateCurrentPassword = (val) =>
  new Promise((resolve) => {
    let error = '';
    if (!val.length) {
      error = errorMsg.passCurrent;
      resolve(error);
    }

    resolve(error);
  });

export const debouncedValidateCurrentPassword = AwesomeDebouncePromise(
  validateCurrentPassword,
  validationTimeLong,
);

export const validatePassword = (val) =>
  new Promise((resolve) => {
    let error = '';

    if (!val) {
      error = errorMsg.required;
      resolve(error);
    }

    if (val.length < 8) {
      error = errorMsg.passCharNum;
      resolve(error);
    }

    const lowercaseRegex = /[a-z]/;
    if (!lowercaseRegex.test(val)) {
      error = errorMsg.passLowercase;
      resolve(error);
    }

    const uppercaseRegex = /[A-Z]/;
    if (!uppercaseRegex.test(val)) {
      error = errorMsg.passUppercase;
      resolve(error);
    }

    const numberRegex = /[0-9]/;
    if (!numberRegex.test(val)) {
      error = errorMsg.passNumber;
      resolve(error);
    }

    resolve(error);
  });

export const debouncedValidatePassword = AwesomeDebouncePromise(
  validatePassword,
  validationTimeLong,
);

export const validatePasswordAgain = (pass1, pass2) =>
  new Promise((resolve) => {
    let error = '';
    if (pass1 !== pass2) {
      error = errorMsg.passMatch;
    }

    if (!pass1) {
      error = errorMsg.required;
    }

    resolve(error);
  });

export const debouncedValidatePasswordAgain = AwesomeDebouncePromise(
  validatePasswordAgain,
  validationTimeLong,
);
