import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ContentLoader,
  DescriptionTable,
  Expander,
  Notice,
  StatusCard,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import {
  capitalizeFirstLetter,
  displayValue,
  formatDate,
} from 'shared/helpers';
import { ResetLicenseForm } from 'licenses/components';
import { getBundleLicenses } from './actions';
import BundleLicenseItem from './BundleLicenseItem';
import LicenseUsers from '../LicenseContainer/LicenseUsers';
import './styles.scss';

const BundleLicenseContainer = ({
  license,
  bundleProduct,
  refetchLicense,
  isLicenseUser,
}) => {
  const { t } = useTranslation();
  const licenseID = get(license, 'id');
  const productName = get(bundleProduct, 'product_name');
  const productAuth = get(bundleProduct, 'authorization_method');
  const isAuthUserBased = productAuth === 'user';

  const [bundleLicensesLoading, setBundleLicensesLoading] = useState(true);
  const [bundleLicenses, setBundleLicenses] = useState([]);

  const [isResetFormDisplayed, setResetFormDisplay] = useState(false);

  const fetchLicenses = useCallback(() => {
    getBundleLicenses(licenseID)
      .then((res) => {
        const data = get(res, 'data.results') || [];
        setBundleLicenses(data);
        setBundleLicensesLoading(false);
      })
      .catch(() => {
        setBundleLicensesLoading(false);
      });
  }, [licenseID]);

  useEffect(() => {
    fetchLicenses();
  }, [fetchLicenses]);

  const formatBundleLicenseForExpander = (licenses) =>
    licenses.map((l) => {
      const prodName = get(l, 'product.product_name');
      const status = get(l, 'status');
      const licenseStatus = status === 'Active' ? 'success' : 'error';

      return {
        heading: <StatusCard text={prodName} status={licenseStatus} />,
        body: (
          <BundleLicenseItem
            license={l}
            refetchLicense={fetchLicenses}
            isLicenseUser={isLicenseUser}
            product={get(l, 'product')}
          />
        ),
      };
    });

  const showUserTab = isAuthUserBased && !isLicenseUser;

  return (
    <div className="BundleLicenseContainer">
      <div className="list-header">
        <div>
          <Button theme="warning" onClick={() => setResetFormDisplay(true)}>
            {t('Reset')}
          </Button>
        </div>
        <div />
      </div>
      <Notice theme="info" size="sm" title={t('Bundle license')}>
        <div>
          <div>
            {t(
              'This is a bundle license. Check individual product licenses for more detailed overview.',
            )}
          </div>
        </div>
      </Notice>
      <Tabs>
        <TabsHeader>
          <Tab>{t('License details')}</Tab>
          {showUserTab && <Tab>{t('Users')}</Tab>}
        </TabsHeader>
        <TabContent>
          <div className="BundleLicenseDetails">
            <DescriptionTable
              details={[
                {
                  label: t('Product'),
                  value: displayValue(productName),
                },
                {
                  label: t('Created on'),
                  value: formatDate(get(license, 'created_at')),
                },
                {
                  label: t('Authorization method'),
                  value: capitalizeFirstLetter(displayValue(productAuth)),
                },
                { label: t('Note'), value: displayValue(get(license, 'note')) },
              ]}
            />
          </div>
        </TabContent>
        {showUserTab && (
          <TabContent>
            <LicenseUsers license={license} refetchLicense={refetchLicense} />
          </TabContent>
        )}
      </Tabs>
      <div className="BundleLicenses">
        {bundleLicensesLoading ? (
          <ContentLoader />
        ) : (
          <Expander
            items={formatBundleLicenseForExpander(bundleLicenses)}
            allowMultipleExpanded
            allowZeroExpanded
          />
        )}
      </div>
      {isResetFormDisplayed && (
        <ResetLicenseForm
          close={() => setResetFormDisplay(false)}
          confirmCb={() => setResetFormDisplay(false)}
          license={license}
          getLicense={fetchLicenses}
        />
      )}
    </div>
  );
};

BundleLicenseContainer.propTypes = {
  bundleProduct: PropTypes.object.isRequired,
  license: PropTypes.object.isRequired,
  refetchLicense: PropTypes.func.isRequired,
  isLicenseUser: PropTypes.bool.isRequired,
};

export default BundleLicenseContainer;
