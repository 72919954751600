import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { Modal, Notification } from 'shared/components';
import { resetLicense } from './actions';
import './styles.scss';

const ResetLicenseForm = ({ close, confirmCb, getLicense, license }) => {
  const { t } = useTranslation();
  const [isDisabled, setDisabled] = useState(false);

  const handleReset = () => {
    const licenseId = get(license, 'id');
    setDisabled(true);

    resetLicense(licenseId, license)
      .then(() => {
        setDisabled(false);
        getLicense();
        Notification(
          'success',
          t('Changes saved successfully'),
          t('Your license has been reset'),
        );
        confirmCb();
      })
      .catch(() => {
        setDisabled(false);
        Notification(
          'error',
          t('Your changes were not saved'),
          t('There was an error while saving your changes'),
        );
      });
  };

  const getMessage = () => {
    const licenseKey = get(license, 'license_key');
    if (licenseKey) {
      return (
        <>
          {`${t('This will reset license')} `}
          <span className="license-key">{licenseKey}</span>
        </>
      );
    }
    return (
      <>
        {`${t('This will reset license for product ')} `}
        <span className="license-key">
          {get(license, 'product.product_name')}
        </span>
      </>
    );
  };

  return (
    <Modal
      title={t('Are you sure?')}
      closeCb={close}
      confirmCb={() => handleReset()}
      disabled={isDisabled}
      size="sm"
    >
      <div className="ResetLicenseForm">
        <div className="heading">{getMessage()}</div>
        <div className="bottom">
          {`${t('Order')}:`}
          &nbsp;
          <span>{get(license, 'order_store_id')}</span>
        </div>
      </div>
    </Modal>
  );
};

ResetLicenseForm.propTypes = {
  close: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  getLicense: PropTypes.func.isRequired,
  license: PropTypes.object.isRequired,
};

export default ResetLicenseForm;
