/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { parseObject } from 'shared/helpers';
import { initialPortalData } from 'shared/constants';
import Sidebar from 'sidebar/Sidebar';
import './styles.scss';

const Page = ({
  children,
  description,
  breadcrumb,
  title,
  headerClassname,
}) => {
  const userCompanyData = useSelector(
    (state) => get(state, 'user.customDomain') || {},
  );
  const { portal_data } = userCompanyData;
  const portalData = parseObject(portal_data, initialPortalData);
  const pageBackground = get(portalData, 'pageBackground') || '#fafafa';

  return (
    <section className="Page">
      <Sidebar />
      <div className="Page-wrapper" style={{ backgroundColor: pageBackground }}>
        <header className={`Page-header ${headerClassname}`}>
          <div className="Page-top-container">
            {!!breadcrumb && (
              <div className="Page-breadcrumb">{breadcrumb}</div>
            )}
          </div>
          {!!title && <h1 className="Page-title">{title}</h1>}
          <div className="Page-description">{!!description && description}</div>
        </header>
        <main className="Page-content">{children}</main>
      </div>
    </section>
  );
};

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  breadcrumb: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  headerClassname: PropTypes.string,
};

Page.defaultProps = {
  children: null,
  description: '',
  breadcrumb: '',
  title: null,
  headerClassname: '',
};

export default Page;
