import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
} from 'shared/components';
import { patchUser } from './actions';
import './styles.scss';

const EditProfileForm = ({ close, user }) => {
  const auth = useAuth();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [firstName, setFirstName] = useState(
    get(user, 'profile.given_name') || '',
  );
  const [lastName, setLastName] = useState(
    get(user, 'profile.family_name') || '',
  );
  const [phoneNumber, setPhoneNumber] = useState(
    get(user, 'profile.phone_number') || '',
  );

  const updateValue = (val, cb) => {
    setDirty(true);
    cb(val);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userID = get(user, 'profile.platform_user_id');
    const userData = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
    };

    setLoading(true);

    try {
      await patchUser(userID, userData);
      setLoading(false);
      auth.signinSilent();
      Notification('success', t('Profile updated'));
      close();
      return true;
    } catch (err) {
      setLoading(false);
      Notification(
        'error',
        t('Error occured'),
        t('Your changes were not saved'),
      );
      return false;
    }
  };

  const handleClose = () => {
    if (!dirty) {
      return close();
    }
    return setDirtyFormAlertDisplay(true);
  };

  return (
    <Modal
      title={t('Edit profile')}
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      size="sm"
    >
      <div className="EditProfileForm">
        <form>
          <Label inputId="firstname" text={t('First Name')} />
          <TextInput
            id="firstname"
            handleChange={(val) => updateValue(val, setFirstName)}
            value={firstName}
            disabled={loading}
          />
          <Label inputId="lastname" text={t('Last Name')} />
          <TextInput
            id="lastname"
            handleChange={(val) => updateValue(val, setLastName)}
            value={lastName}
            disabled={loading}
          />
          <Label inputId="phone_number" text={t('Phone number')} />
          <TextInput
            id="phone_number"
            handleChange={(val) => updateValue(val, setPhoneNumber)}
            value={phoneNumber}
            disabled={loading}
          />
        </form>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={close}
        />
      )}
    </Modal>
  );
};

EditProfileForm.propTypes = {
  close: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default EditProfileForm;
