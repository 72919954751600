import React, { useState, useCallback } from 'react';
import get from 'lodash.get';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ContentLoader,
  NotFound,
  Notification,
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import { useAPI } from 'shared/hooks';
import { isUserManager, hasPermission, parseObject } from 'shared/helpers';
import { initialPortalData } from 'shared/constants';
import LicenseDetails from './LicenseDetails';
import LicenseDevices from './LicenseDevices';
import LicenseUsers from './LicenseUsers';
import ProductFeatures from './ProductFeatures';
import BundleLicenseContainer from '../BundleLicenseContainer';
import CustomFields from './CustomFields';
import LicenseOAuth from './LicenseOAuth';

const LicenseContainer = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const userDetails = useSelector((state) => get(state, 'user.details'));
  const isManager = isUserManager(userDetails);
  const licenseID = get(match, 'params.licenseId');
  const companyID = String(get(userDetails, 'company.id'));
  const portal_data = get(userDetails, 'company.portal_data');
  const portalData = parseObject(portal_data, initialPortalData);
  const { hideCustomFields, hideProductFeatures } = portalData;
  const permissionsList = userDetails.permissions || [];
  const canViewOAuth = hasPermission(
    permissionsList,
    'show_license_oauth_clients'
  );
  const canManageOAuth = hasPermission(
    permissionsList,
    'manage_license_oauth_clients'
  );

  const [shouldGetLicenseAgain, setShouldGetLicenseAgain] = useState(0);

  const onErrorCallback = useCallback(
    () =>
      Notification(
        'error',
        t('Error occured'),
        t('There was an error while getting license data')
      ),
    [t]
  );

  const { isLoading: loading, value: license = null } = useAPI({
    url: `/api/v1/licenses/${licenseID}/`,
    onError: onErrorCallback,
    dependenciesArray: [licenseID, shouldGetLicenseAgain],
  });

  const refetchLicense = () =>
    setShouldGetLicenseAgain(shouldGetLicenseAgain + 1);

  if (loading) {
    return (
      <Page
        title=""
        breadcrumb={<NavLink to="/licenses">{t('Licenses')}</NavLink>}
      >
        <ContentLoader text={t('Getting license details')} />
      </Page>
    );
  }

  if (!license) {
    return (
      <Page
        title=""
        breadcrumb={<NavLink to="/licenses">{t('Licenses')}</NavLink>}
      >
        <NotFound />
      </Page>
    );
  }

  const displayLicenseTitle = () => {
    const licenseKey = get(license, 'license_key') || '';
    const licenseUsers = get(license, 'license_users') || [];
    const isFloating =
      get(license, 'is_floating') || get(license, 'is_floating_cloud');
    if (licenseKey && !licenseUsers.length) {
      return (
        <>
          {`${t('License')}: ${licenseKey}`}
          {isFloating && <span className="title-tag">{t('Floating')}</span>}
        </>
      );
    }
    return (
      <>
        {`${t('License for')}: ${get(license, 'product.product_name')}`}
        {isFloating && <span className="title-tag">{t('Floating')}</span>}
      </>
    );
  };

  // redundacy for edge cases when manager can also view licenses where he is only user
  const isLicenseManager = get(license, 'is_manager');

  const isUserAuth = get(license, 'product.authorization_method') === 'user';
  const product = get(license, 'product');
  const isBundleLicense = get(license, 'is_bundle');

  const isLicenseUser = !isManager || !isLicenseManager;
  const showUserTab = isUserAuth && !isLicenseUser;

  if (isBundleLicense) {
    return (
      <Page
        title={displayLicenseTitle()}
        breadcrumb={<NavLink to="/licenses">{t('Licenses')}</NavLink>}
      >
        <div className="LicenseContainer BundleLicenseContainer">
          <BundleLicenseContainer
            license={license}
            bundleProduct={product}
            refetchLicense={refetchLicense}
            isLicenseUser={isLicenseUser}
          />
        </div>
      </Page>
    );
  }

  return (
    <Page
      title={displayLicenseTitle()}
      breadcrumb={<NavLink to="/licenses">{t('Licenses')}</NavLink>}
    >
      <div className="LicenseContainer">
        <Tabs>
          <TabsHeader>
            <Tab>{t('License details')}</Tab>
            {!hideProductFeatures && <Tab>{t('Product features')}</Tab>}
            {!hideCustomFields && <Tab>{t('Custom fields')}</Tab>}
            <Tab>{t('Devices')}</Tab>
            {showUserTab && <Tab>{t('License users')}</Tab>}
            {isLicenseManager && (canManageOAuth || canViewOAuth) && (
              <Tab>{t('OAuth')}</Tab>
            )}
          </TabsHeader>
          <TabContent>
            <LicenseDetails
              license={license}
              getLicense={refetchLicense}
              isLicenseUser={isLicenseUser}
            />
          </TabContent>
          {!hideProductFeatures && (
            <TabContent>
              <ProductFeatures license={license} />
            </TabContent>
          )}
          {!hideCustomFields && (
            <TabContent>
              <CustomFields license={license} product={product} />
            </TabContent>
          )}
          <TabContent>
            <LicenseDevices license={license} isLicenseUser={isLicenseUser} />
          </TabContent>
          {showUserTab && (
            <TabContent>
              <LicenseUsers license={license} getLicense={refetchLicense} />
            </TabContent>
          )}
          {isLicenseManager && (canManageOAuth || canViewOAuth) && (
            <TabContent>
              <LicenseOAuth licenseID={licenseID} companyID={companyID} />
            </TabContent>
          )}
        </Tabs>
      </div>
    </Page>
  );
};

export default LicenseContainer;
