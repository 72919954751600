import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ConfirmationPopup,
  HiddenPasswordField,
  IconDelete,
  IconEdit,
  IconPassword,
  List,
  Notification,
} from 'shared/components';
import { displayValue, hasPermission } from 'shared/helpers';
import {
  getOrderManagers,
  removeOrderManager,
} from 'orders/OrderContainer/actions';
import { OrderManagerForm, OrderManagerPasswordForm } from './components';

const OrderManagers = ({ order }) => {
  const { t } = useTranslation();
  const permissionsList = useSelector((state) =>
    get(state, 'user.details.permissions'),
  );
  const orderID = get(order, 'id');
  const customerEmail = get(order, 'customer.email');

  const [isLoading, setLoading] = useState(true);
  const [orderManagers, setOrderManagers] = useState([]);
  // add/edit manager form
  const [orderManagerToEdit, setOrderManagerToEdit] = useState(null);
  const [showOrderManagerForm, setOrderManagerFormDisplay] = useState(false);
  // remove manager
  const [orderManagerToRemove, setOrderManagerToRemove] = useState(null);
  const [showManagerRemovePopup, setManagerRemovePopupDisplay] = useState(null);
  const [isRemoveManagerLoading, setRemoveManagerLoading] = useState(false);
  // change manager pass
  const [showOrderManagerPasswordForm, setOrderManagerPasswordForm] =
    useState(false);
  const [orderManagerToPasswordChange, setOrderManagerToPasswordChange] =
    useState(null);

  const checkPermission = () => {
    const permission = hasPermission(permissionsList, 'manage_managers');
    if (!permission) {
      Notification(
        'error',
        t('Missing permission'),
        t('You do not have permission to perform this action.'),
      );
      return false;
    }
    return true;
  };

  const fetchManagers = useCallback(() => {
    getOrderManagers(orderID)
      .then((res) => {
        const data = get(res, 'data') || [];
        setOrderManagers(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [orderID]);

  useEffect(() => {
    fetchManagers();
  }, [fetchManagers]);

  const handleRemoveOrderManager = () => {
    const managerToRemoveID = get(orderManagerToRemove, 'id');
    setRemoveManagerLoading(true);

    removeOrderManager(orderID, managerToRemoveID)
      .then(() => {
        const newList = orderManagers.filter(
          (om) => get(om, 'id') !== managerToRemoveID,
        );
        setOrderManagers(newList);
        setRemoveManagerLoading(false);
        setManagerRemovePopupDisplay(false);
        setOrderManagerToRemove(null);
        Notification('success', t('Changes saved successfully'));
      })
      .catch(() => {
        setRemoveManagerLoading(false);
        Notification(
          'error',
          t('Your changes were not saved'),
          t('There was an error while saving your changes'),
        );
      });
  };

  const isCustomer = (manager) => {
    const managerEmail = get(manager, 'original.true_email');
    return managerEmail === customerEmail;
  };

  const handleAddManager = () => {
    const isAllowed = checkPermission();
    if (!isAllowed) {
      return false;
    }
    setOrderManagerFormDisplay(true);
    return true;
  };

  const handlePasswordChangeClick = (rowData) => {
    const isAllowed = checkPermission();
    if (!isAllowed) {
      return false;
    }
    setOrderManagerToPasswordChange(rowData.original);
    setOrderManagerPasswordForm(true);
    return true;
  };

  const handleManagerEditClick = (rowData) => {
    const isAllowed = checkPermission();
    if (!isAllowed) {
      return false;
    }
    setOrderManagerToEdit(rowData.original);
    setOrderManagerFormDisplay(true);
    return true;
  };

  const handleManagerDeleteClick = (rowData) => {
    const isAllowed = checkPermission();
    if (!isAllowed) {
      return false;
    }
    setOrderManagerToRemove(rowData.original);
    setManagerRemovePopupDisplay(true);
    return true;
  };

  return (
    <div className="OrderManagers order-managers-tab">
      <div className="order-managers-tab-actions">
        <Button onClick={handleAddManager} theme="info" size="sm">
          {t('Add license manager')}
        </Button>
      </div>
      <List
        columns={[
          {
            accessor: 'true_email',
            Header: t('Email'),
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 200,
          },
          {
            accessor: 'first_name',
            Header: t('First Name'),
            Cell: (cellData) => displayValue(cellData.value),
          },
          {
            accessor: 'last_name',
            Header: t('Last Name'),
            Cell: (cellData) => displayValue(cellData.value),
          },
          {
            accessor: 'phone_number',
            Header: t('Phone number'),
            Cell: (cellData) => displayValue(cellData.value),
          },
          {
            accessor: 'is_initial_password',
            Header: t('Initial password'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (cellData) => {
              if (!cellData.value) {
                return t('N/A');
              }
              const initialPass = get(cellData, 'original.initial_password');
              return (
                <HiddenPasswordField value={initialPass} fallback={t('N/A')} />
              );
            },
          },
          {
            Header: t('Change password'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (rowData) => (
              <Button
                className="edit-button"
                onClick={() => handlePasswordChangeClick(rowData)}
                type="button"
              >
                <IconPassword height="24px" width="24px" viewBox="0 0 65 60" />
              </Button>
            ),
          },
          {
            Cell: (rowData) => (
              <Button
                className="edit-button"
                onClick={() => handleManagerEditClick(rowData)}
                type="button"
              >
                <IconEdit height="16px" width="16px" />
              </Button>
            ),
            maxWidth: 50,
          },
          {
            Cell: (rowData) => (
              <Button
                className="edit-button"
                onClick={() => handleManagerDeleteClick(rowData)}
                type="button"
                disabled={isCustomer(rowData)}
              >
                <IconDelete height="16px" width="16px" color="#ee5253" />
              </Button>
            ),
            maxWidth: 50,
          },
        ]}
        data={orderManagers}
        loading={isLoading}
        minRows={2}
        pageSize={20}
      />
      {showOrderManagerForm && (
        <OrderManagerForm
          orderID={orderID}
          closeCb={() => {
            setOrderManagerToEdit(null);
            setOrderManagerFormDisplay(false);
          }}
          confirmCb={() => {
            fetchManagers();
            setOrderManagerToEdit(null);
            setOrderManagerFormDisplay(false);
          }}
          orderManagers={orderManagers}
          manager={orderManagerToEdit}
        />
      )}
      {showOrderManagerPasswordForm && (
        <OrderManagerPasswordForm
          closeCb={() => {
            setOrderManagerPasswordForm(false);
            setOrderManagerToPasswordChange(null);
          }}
          refetchManagers={fetchManagers}
          manager={orderManagerToPasswordChange}
        />
      )}
      {showManagerRemovePopup && (
        <ConfirmationPopup
          closeCb={() => {
            setOrderManagerToRemove(null);
            setManagerRemovePopupDisplay(false);
          }}
          confirmCb={handleRemoveOrderManager}
          title={t('Are you sure you want to remove this license manager?')}
          confirmText={t('Remove')}
          theme="error"
          disabled={isRemoveManagerLoading}
        >
          {get(orderManagerToRemove, 'true_email')}
        </ConfirmationPopup>
      )}
    </div>
  );
};

OrderManagers.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderManagers;
