import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { v4 as uuid4 } from 'uuid';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, DescriptionTable, Notification } from 'shared/components';
import { authMethods } from 'shared/constants';
import { generateLicense } from '../../actions';
import { getCustomerData } from './helpers';
import SelectedProductsTable from '../SelectedProductsTable';
import SelectedBundlesTable from '../SelectedBundlesTable';
import './styles.scss';

const VerifyOrderStep = ({
  companyID,
  formState,
  handleBack,
  handleOrderCreate,
  loading,
  orderID,
}) => {
  const { t } = useTranslation();
  const userProducts = useSelector(
    (state) => get(state, 'user.details.products') || [],
  );
  // eslint-disable-next-line no-unused-vars
  const counter = useSelector((state) => get(state, 'user.counter') || []);
  const [generatingLicenses, setGeneratingLicenses] = useState(false);

  const isBundle = get(formState, 'is_bundle');
  const bundle = get(formState, 'bundle');

  const products = get(formState, 'products') || [];
  const existingCustomer = get(formState, 'existingCustomer');
  const customerData = {
    email: getCustomerData(
      formState,
      existingCustomer,
      'customerEmail',
      'email',
    ),
    first_name: getCustomerData(
      formState,
      existingCustomer,
      'customerFirstName',
      'first_name',
    ),
    last_name: getCustomerData(
      formState,
      existingCustomer,
      'customerLastName',
      'last_name',
    ),
    company_name: getCustomerData(
      formState,
      existingCustomer,
      'customerOrganization',
      'company_name',
    ),
    address: getCustomerData(
      formState,
      existingCustomer,
      'customerAddress',
      'address',
    ),
    city: getCustomerData(formState, existingCustomer, 'customerCity', 'city'),
    state: getCustomerData(
      formState,
      existingCustomer,
      'customerState',
      'state',
    ),
    country: getCustomerData(
      formState,
      existingCustomer,
      'customerCountry',
      'country',
    ),
    postcode: getCustomerData(
      formState,
      existingCustomer,
      'customerZipcode',
      'postcode',
    ),
    phone: getCustomerData(
      formState,
      existingCustomer,
      'customerPhoneNumber',
      'phone',
    ),
    reference: getCustomerData(
      formState,
      existingCustomer,
      'customerReference',
      'reference',
    ),
    is_manager: get(formState, 'is_manager'),
  };

  const handleBackClick = () => handleBack();

  const generateLicenseKeys = async (productCode, licenseNum) => {
    let licenses = null;

    try {
      licenses = await generateLicense(companyID, productCode, licenseNum);
    } catch (error) {
      Notification('error', t('Error occured'));
    }

    return get(licenses, 'data');
  };

  const findTotalNumberOfLicenses = (orderItems, productID) => {
    // Filter the products array to find the ones with the given product code
    const orderLicenses = orderItems.filter(
      (oi) => get(oi, 'product_id') === productID,
    );

    // Initialize the count of licenses
    let totalLicenses = 0;

    // Loop through the matching products and add the number of licenses for each product
    orderLicenses.forEach((ol) => {
      totalLicenses += ol.licenses.length;
    });

    return totalLicenses;
  };

  const validateLicenseNum = (orderItems) => {
    counter.forEach((c) => {
      const productID = get(c, 'product.id');
      const maxLicenses = get(c, 'num_of_licenses');

      if (maxLicenses === null) {
        return true;
      }
      const createdLicenses = get(c, 'created_licenses_count');
      const remainingLicenses = maxLicenses - createdLicenses;
      const productLicensesCount = findTotalNumberOfLicenses(
        orderItems,
        productID,
      );
      const newCount = createdLicenses + productLicensesCount;
      if (newCount > maxLicenses) {
        Notification(
          'error',
          `${t('Allowed licenses exceeded for product')} ${get(c, 'product.product_name')}`,
          `${t('Remaining licenses')}: ${remainingLicenses}`,
        );
        throw new Error();
      }
      return true;
    });
  };

  const handleBundleOrderCreateClick = async () => {
    if (loading || generatingLicenses) {
      return false;
    }
    setGeneratingLicenses(true);

    const uuidID = uuid4().split('-').join('');

    const bundleCode = get(bundle, 'bundle.data.short_code');
    const isBundleKeyAuth =
      get(bundle, 'bundle.data.authorization_method') === authMethods.key;
    let keys;
    if (isBundleKeyAuth) {
      keys = await generateLicenseKeys(bundleCode, 1);
    }

    const bundlePolicies = get(bundle, 'selected_bundle_policies') || [];
    const bundleProducts = bundlePolicies.map((p) => {
      const prod = userProducts.find((i) => i.id === get(p, 'product.id'));
      return {
        license_template_code: p.code,
        product_code: prod.short_code,
      };
    });

    const bundleItem = {
      product_code: bundleCode,
      key: isBundleKeyAuth ? keys[0] : undefined, // bundle license key
      users: [],
      licenses: bundleProducts,
    };

    const data = {
      id: get(formState, 'orderId') || uuidID,
      is_bundle: true,
      append: !!orderID,
      customer: customerData,
      items: [bundleItem],
      reference: get(formState, 'orderReference') || '',
    };
    handleOrderCreate(data);
    setGeneratingLicenses(false);
    return true;
  };

  const handleOrderCreateClick = async () => {
    if (loading || generatingLicenses) {
      return false;
    }
    setGeneratingLicenses(true);

    const orderItems = await Promise.all(
      products.map(async (product) => {
        const licenseNum = Number(get(product, 'license_num') || 1);
        const productCode = get(product, 'short_code');
        const isKeyAuth =
          get(product, 'authorization_method') === authMethods.key;

        let licenseKeys = null;
        if (isKeyAuth) {
          licenseKeys = await generateLicenseKeys(productCode, licenseNum);
        }
        let item;

        if (isKeyAuth && Array.isArray(licenseKeys) && licenseKeys.length) {
          item = {
            product_id: get(product, 'id'),
            product_code: productCode,
            note: get(product, 'note'),
            licenses: licenseKeys.map((lk) => ({
              key: lk,
              license_template_code: get(
                product,
                'selected_license_policy.code',
              ),
            })),
          };
        }

        if (!isKeyAuth) {
          item = {
            product_id: get(product, 'id'),
            product_code: productCode,
            licenses: [
              {
                license_template_code: get(
                  product,
                  'selected_license_policy.code',
                ),
                users: [],
              },
            ],
          };
        }

        return item;
      }),
    );

    try {
      validateLicenseNum(orderItems);
    } catch (error) {
      setGeneratingLicenses(false);
      return false;
    }

    const uuidID = uuid4().split('-').join('');

    const data = {
      id: get(formState, 'id') || uuidID,
      append: !!orderID,
      customer: customerData,
      items: orderItems,
      reference: get(formState, 'orderReference') || '',
    };

    handleOrderCreate(data);
    setGeneratingLicenses(false);
    return true;
  };

  const orderReference = get(formState, 'orderReference');

  return (
    <div className="VerifyOrderStep">
      <section className="VerifyOrderStep-customer">
        {orderID || get(formState, 'id') ? (
          <div className="section-header order-id">
            {`${t('Order ID')}:`}
            <span>{get(formState, 'id')}</span>
          </div>
        ) : null}
        <div className="section-header reference">
          {orderReference && (
            <>
              {`${t('Order reference')}:`}
              <span>{orderReference}</span>
            </>
          )}
        </div>
        <div className="section-header">{t('Customer details')}</div>
        <DescriptionTable
          details={[
            { label: t('Email'), value: customerData.email },
            { label: t('First Name'), value: customerData.first_name },
            { label: t('Last Name'), value: customerData.last_name },
            { label: t('Company'), value: customerData.company_name },
            { label: t('Address'), value: customerData.address },
            { label: t('City'), value: customerData.city },
            { label: t('State / Province'), value: customerData.state },
            { label: t('Country'), value: customerData.country },
            { label: t('Zipcode / Postcode'), value: customerData.postcode },
            { label: t('Phone number'), value: customerData.phone },
            { label: t('Reference'), value: customerData.reference },
            {
              label: t('Is license manager'),
              value: customerData.is_manager ? t('Yes') : t('No'),
            },
          ]}
        />
      </section>
      <section className="VerifyOrderStep-products">
        <div className="section-header">{t('Order products')}</div>
        {isBundle ? (
          <SelectedBundlesTable
            companyID={companyID}
            selectedBundles={[bundle]}
            showActionBtns={false}
          />
        ) : (
          <SelectedProductsTable
            companyID={companyID}
            selectedProducts={products}
            showActionBtns={false}
          />
        )}
      </section>
      <div className="ProductsStep-actions">
        <Button
          disabled={loading || generatingLicenses}
          onClick={handleBackClick}
          size="lg"
          theme="default"
        >
          {t('Back')}
        </Button>
        <Button
          disabled={loading || generatingLicenses}
          onClick={() => {
            if (isBundle) {
              handleBundleOrderCreateClick();
              return true;
            }
            handleOrderCreateClick();
            return true;
          }}
          size="lg"
          theme="success"
        >
          {t('Create order')}
        </Button>
      </div>
    </div>
  );
};

VerifyOrderStep.propTypes = {
  companyID: PropTypes.number.isRequired,
  formState: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleOrderCreate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  orderID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

VerifyOrderStep.defaultProps = {
  loading: false,
  orderID: null,
};

export default VerifyOrderStep;
