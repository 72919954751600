import api from 'shared/api';

export const getOauthLicenseClient = (licenseID, companyID) =>
  api.get(`/api/v1/licenses/${licenseID}/client/?company=${companyID}`);

export const createOauthLicenseClient = (licenseID, companyID) =>
  api.post(`/api/v1/licenses/${licenseID}/client/?company=${companyID}`);

export const deleteOauthLicenseClient = (licenseID, companyID) =>
  api.delete(`/api/v1/licenses/${licenseID}/client/?company=${companyID}`);

export const createOauthLicenseClientSecret = (licenseID, companyID) =>
  api.post(`/api/v1/licenses/${licenseID}/client_secret/?company=${companyID}`);

export const rotateOauthLicenseClientSecret = (licenseID, companyID, data) =>
  api.post(
    `/api/v1/licenses/${licenseID}/client_secret_rotate/?company=${companyID}`,
    data,
  );
