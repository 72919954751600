import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  DirtyFormAlert,
  Modal,
  Label,
  DateInput,
  Notice,
  Notification,
  RadioBtn,
} from 'shared/components';
import { validateDate } from 'shared/validation';
import { validityPeriodTimeFormat, APIdateFormat } from 'shared/constants';
import { borrowDevice } from '../../LicenseContainer/LicenseDevices/actions';
import './styles.scss';

const BorrowLicenseForm = ({ closeCb, confirmCb, device, license }) => {
  const { t } = useTranslation();
  const maxBorrowTime = get(license, 'max_borrow_time') || 0;
  const initialDate = get(device, 'borrowed_until');
  const initialBorrowTime = initialDate
    ? moment(initialDate).format(APIdateFormat)
    : null;
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(true);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);
  const [borrowUntil, setBorrowUntil] = useState(initialBorrowTime);
  const [borrowUntilError, setBorrowUntilError] = useState('');
  const [selectedBorrowType, setSelectedBorrowType] = useState('max');

  const validateDateField = async (val, cb, isOptional = false) => {
    if (selectedBorrowType === 'max') {
      return true;
    }
    if (!val && isOptional) {
      cb('');
      return true;
    }

    setLoading(true);
    let errors;

    try {
      errors = await validateDate(val);
      setLoading(false);
      cb(errors);
    } catch (err) {
      setLoading(false);
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const validateMaxBorrowTimeValid = () => {
    if (selectedBorrowType === 'max') {
      return true;
    }
    const hoursDiff = moment(borrowUntil).diff(moment(), 'hours', true);
    const isBiggerThanAllowed = hoursDiff > maxBorrowTime;
    if (hoursDiff <= 0) {
      Notification('error', t('Only future dates can be selected'));
      return false;
    }
    if (isBiggerThanAllowed) {
      Notification(
        'error',
        t('Selected time period is longer than license max borrow time.'),
      );
      return false;
    }
    return true;
  };

  const isFormValid = async () => {
    const isValid = await validateDateField(borrowUntil, setBorrowUntilError);
    const isBorrowRangeValid = validateMaxBorrowTimeValid();
    return isValid && isBorrowRangeValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isDirty) {
      closeCb();
      return false;
    }

    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setLoading(true);
    const deviceID = get(device, 'id');
    const datetime = moment(borrowUntil).format(validityPeriodTimeFormat);
    const data = selectedBorrowType === 'max' ? null : datetime;
    borrowDevice(deviceID, data)
      .then(() => {
        Notification('success', t('Changes saved successfully'));
        confirmCb();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          t('Your changes were not saved'),
          t('There was an error while saving your changes'),
        );
      });
    return true;
  };

  const handleSelectedBorrowType = (val) => {
    setDirty(true);
    setSelectedBorrowType(val);
    setBorrowUntil(initialBorrowTime);
    setBorrowUntilError('');
  };

  const handleClose = () => {
    if (!isDirty) {
      return closeCb();
    }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      title={t('Borrow license')}
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      size="sm"
    >
      <div className="BorrowLicenseForm">
        <Notice>{`${t('Max borrow time')}: ${maxBorrowTime} ${t('hours')}`}</Notice>
        <div className="row">
          <RadioBtn
            name="borrow/type/select"
            inputId="borrow/max"
            label={t('Borrow for max allowed period')}
            value="max"
            checked={selectedBorrowType === 'max'}
            handleChange={handleSelectedBorrowType}
          />
          <RadioBtn
            name="borrow/type/select"
            inputId="borrow-date"
            label={t('Input borrow end date')}
            value="date"
            checked={selectedBorrowType !== 'max'}
            handleChange={handleSelectedBorrowType}
          />
          {selectedBorrowType !== 'max' && (
            <div>
              <Label text={t('Borrowed until')} inputId="borrow-until" />
              <DateInput
                handleChange={(val) => {
                  setDirty(true);
                  setBorrowUntil(val);
                }}
                value={borrowUntil}
                error={borrowUntilError}
                id="borrow-until"
              />
            </div>
          )}
        </div>
        {isDirtyFormDisplayed && (
          <DirtyFormAlert
            dirty={isDirty}
            closeAlert={() => setDirtyFormDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </Modal>
  );
};

BorrowLicenseForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  license: PropTypes.object.isRequired,
};

export default BorrowLicenseForm;
