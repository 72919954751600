import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  DescriptionTable,
  IconCheckmark,
  IconDisabled,
} from 'shared/components';
import { displayValue, formatDate } from 'shared/helpers';

const OrderDetails = ({ order }) => {
  const { t } = useTranslation();
  return (
    <div className="OrderDetails order-details">
      <DescriptionTable
        details={[
          {
            label: t('Created on'),
            value: formatDate(get(order, 'created_at')),
          },
          { label: t('Type'), value: displayValue(get(order, 'type')) },
          {
            label: t('Is trial'),
            value: get(order, 'is_trial') ? (
              <IconCheckmark color="#10ac84" height="14px" />
            ) : (
              <IconDisabled color="#aaa" height="14px" />
            ),
          },
          {
            label: t('Is test'),
            value: get(order, 'is_test') ? (
              <IconCheckmark color="#10ac84" height="14px" />
            ) : (
              <IconDisabled color="#aaa" height="14px" />
            ),
          },
        ]}
      />
    </div>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderDetails;
