import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Label,
  Notification,
  TextInput,
  PhoneNumberInput,
} from 'shared/components';
import { validateEmail } from 'shared/validation';
import ExistingOrderDetails from './ExistingOrderDetails';
import './styles.scss';

const CustomerStep = ({
  formState,
  handleForward,
  handleSubmit,
  orderID,
  setDirty,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [id, setOrderID] = useState(get(formState, 'id'));
  const [orderReference, setOrderReference] = useState(
    get(formState, 'orderReference'),
  );
  const [reference, setReference] = useState(
    get(formState, 'customerReference'),
  );
  const [referenceError, setReferenceError] = useState('');
  const [email, setEmail] = useState(get(formState, 'customerEmail'));
  const [emailError, setEmailError] = useState('');
  const [emailLoading, setEmailLoading] = useState(false);
  const [firstName, setFirstName] = useState(
    get(formState, 'customerFirstName'),
  );
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState(get(formState, 'customerLastName'));
  const [lastNameError, setLastNameError] = useState('');
  const [organization, setOrganization] = useState(
    get(formState, 'customerOrganization'),
  );
  const [organizationError, setOrganizationError] = useState('');
  const [address, setAddress] = useState(get(formState, 'customerAddress'));
  const [addressError, setAddressError] = useState('');
  const [city, setCity] = useState(get(formState, 'customerCity'));
  const [cityError, setCityError] = useState('');
  const [stateProvince, setStateProvince] = useState(
    get(formState, 'customerState'),
  );
  const [stateProvinceError, setStateProvinceError] = useState('');
  const [country, setCountry] = useState(get(formState, 'customerCountry'));
  const [countryError, setCountryError] = useState('');
  const [zipcode, setZipcode] = useState(get(formState, 'customerZipcode'));
  const [zipcodeError, setZipcodeError] = useState('');
  const [phone, setPhone] = useState(get(formState, 'customerPhoneNumber'));
  const [phoneError, setPhoneError] = useState('');
  const [isManager, setIsManager] = useState(get(formState, 'is_manager'));

  useEffect(() => {
    setOrderID(get(formState, 'id'));
    setReference(get(formState, 'customerReference'));
    setEmail(get(formState, 'customerEmail'));
    setFirstName(get(formState, 'customerFirstName'));
    setLastName(get(formState, 'customerLastName'));
    setOrganization(get(formState, 'customerOrganization'));
    setAddress(get(formState, 'customerAddress'));
    setCity(get(formState, 'customerCity'));
    setStateProvince(get(formState, 'customerState'));
    setCountry(get(formState, 'customerCountry'));
    setZipcode(get(formState, 'customerZipcode'));
    setPhone(get(formState, 'customerPhoneNumber'));
  }, [formState]);

  if (orderID) {
    return (
      <div className="CustomerStep">
        <ExistingOrderDetails
          formState={formState}
          handleForward={handleForward}
        />
      </div>
    );
  }

  const validateCustomerEmail = async (val) => {
    setEmailLoading(true);
    let errors;

    try {
      errors = await validateEmail(val);
      setEmailLoading(false);
      setEmailError(errors);
    } catch (err) {
      setEmailLoading(false);
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const handleEmailChange = (val) => {
    setDirty(true);
    setEmail(val);
    validateCustomerEmail(val);
  };

  const validateCustomer = () => {
    if (orderID) {
      return true;
    }
    return validateCustomerEmail(email);
  };

  const isFormValid = async () => {
    const isCustomerValid = await validateCustomer();
    return !!isCustomerValid;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const isValid = await isFormValid();

    if (!isValid || loading) {
      Notification(
        'error',
        t('Please enter correct information and try again'),
      );
      return false;
    }

    setLoading(true);

    const data = {
      id,
      orderReference,
      customerReference: reference,
      customerFirstName: firstName,
      customerLastName: lastName,
      customerEmail: email,
      customerOrganization: organization,
      customerAddress: address,
      customerCity: city,
      customerState: stateProvince,
      customerCountry: country,
      customerZipcode: zipcode,
      customerPhoneNumber: phone,
      is_manager: isManager,
    };

    return handleSubmit(data);
  };

  return (
    <div className="CustomerStep">
      <form
        className="CustomerStep-form"
        id="customerDataForm"
        onSubmit={handleFormSubmit}
      >
        <div className="order-details">
          <div className="form-row">
            <div className="form-column">
              <Label text={t('Order ID')} inputId="customer-order-id" />
              <TextInput
                id="customer-order-id"
                handleChange={(val) => {
                  setDirty(true);
                  setOrderID(val);
                }}
                value={id}
              />
            </div>
            <div className="form-column">
              <Label text={t('Order reference')} inputId="order-reference" />
              <TextInput
                id="order-reference"
                handleChange={(val) => {
                  setDirty(true);
                  setOrderReference(val);
                }}
                value={orderReference}
              />
            </div>
          </div>
          <div className="form-row is-customer-manager">
            <Checkbox
              label={t(
                'Assign the customer as a License Manager for the Order',
              )}
              inputId="is-license-manager"
              checked={isManager}
              handleChange={(val) => setIsManager(val)}
            />
          </div>
        </div>
        <div className="customer-details">
          <h4>{t('Customer details')}</h4>
          <div className="customer-details-input-fields">
            <div className="new-customer-form">
              <div className="form-row">
                <div className="form-column wide">
                  <Label text={t('Email')} inputId="customer-order-email" />
                  <TextInput
                    id="customer-order-email"
                    handleChange={handleEmailChange}
                    value={email}
                    error={emailError}
                    loading={emailLoading}
                    type="email"
                  />
                </div>
                <div className="form-column wide">
                  <Label
                    text={t('Customer reference')}
                    inputId="customer-reference"
                  />
                  <TextInput
                    id="customer-reference"
                    handleChange={(val) => {
                      setDirty(true);
                      setReference(val);
                      setReferenceError('');
                    }}
                    value={reference}
                    error={referenceError}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <Label
                    text={t('First Name')}
                    inputId="customer-order-firstname"
                  />
                  <TextInput
                    id="customer-order-firstname"
                    handleChange={(val) => {
                      setDirty(true);
                      setFirstName(val);
                      setFirstNameError('');
                    }}
                    value={firstName}
                    error={firstNameError}
                  />
                </div>
                <div className="form-column">
                  <Label
                    text={t('Last Name')}
                    inputId="customer-order-lastname"
                  />
                  <TextInput
                    id="customer-order-lastname"
                    handleChange={(val) => {
                      setDirty(true);
                      setLastName(val);
                      setLastNameError('');
                    }}
                    value={lastName}
                    error={lastNameError}
                  />
                </div>
                <div className="form-column">
                  <Label
                    text={t('Organization')}
                    inputId="customer-order-organization"
                  />
                  <TextInput
                    id="customer-order-organization"
                    handleChange={(val) => {
                      setDirty(true);
                      setOrganization(val);
                      setOrganizationError('');
                    }}
                    value={organization}
                    error={organizationError}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <Label text={t('Address')} inputId="customer-order-address" />
                  <TextInput
                    id="customer-order-address"
                    handleChange={(val) => {
                      setDirty(true);
                      setAddress(val);
                      setAddressError('');
                    }}
                    value={address}
                    error={addressError}
                  />
                </div>
                <div className="form-column">
                  <Label text={t('City')} inputId="customer-order-city" />
                  <TextInput
                    id="customer-order-city"
                    handleChange={(val) => {
                      setDirty(true);
                      setCity(val);
                      setCityError('');
                    }}
                    value={city}
                    error={cityError}
                  />
                </div>
                <div className="form-column">
                  <Label
                    text={t('State / Province')}
                    inputId="customer-order-state"
                  />
                  <TextInput
                    id="customer-order-state"
                    handleChange={(val) => {
                      setDirty(true);
                      setStateProvince(val);
                      setStateProvinceError('');
                    }}
                    value={stateProvince}
                    error={stateProvinceError}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <Label text={t('Country')} inputId="customer-order-country" />
                  <TextInput
                    id="customer-order-country"
                    handleChange={(val) => {
                      setDirty(true);
                      setCountry(val);
                      setCountryError('');
                    }}
                    value={country}
                    error={countryError}
                  />
                </div>
                <div className="form-column">
                  <Label
                    text={t('Zipcode / Postcode')}
                    inputId="customer-order-zipcode"
                  />
                  <TextInput
                    id="customer-order-zipcode"
                    handleChange={(val) => {
                      setDirty(true);
                      setZipcode(val);
                      setZipcodeError('');
                    }}
                    value={zipcode}
                    error={zipcodeError}
                  />
                </div>
                <div className="form-column">
                  <Label
                    text={t('Phone number')}
                    inputId="customer-order-phone"
                  />
                  <PhoneNumberInput
                    handleChange={(val) => {
                      setDirty(true);
                      setPhone(val);
                      setPhoneError('');
                    }}
                    value={phone}
                    error={phoneError}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="CustomerStep-actions">
        <Button
          theme="info"
          size="lg"
          onClick={handleFormSubmit}
          disabled={loading || emailLoading}
        >
          {t('Next')}
        </Button>
      </div>
    </div>
  );
};

CustomerStep.propTypes = {
  formState: PropTypes.object.isRequired,
  handleForward: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  orderID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setDirty: PropTypes.func.isRequired,
};

CustomerStep.defaultProps = {
  orderID: null,
};

export default CustomerStep;
