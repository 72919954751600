import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InputErrorMessage } from 'shared/components';
import './styles.scss';

const Checkbox = ({
  disabled,
  error,
  inputId,
  value,
  label,
  handleChange,
  ...rest
}) => {
  const { t } = useTranslation();
  const handleInputChange = (e) => handleChange(e.target.checked);

  return (
    <div className="Checkbox">
      <label htmlFor={inputId}>
        <input
          tabIndex={0}
          type="checkbox"
          className={error ? 'has-error' : ''}
          id={inputId}
          checked={value}
          disabled={disabled}
          onChange={(e) => handleInputChange(e)}
          {...rest}
        />
        {label}
      </label>
      <InputErrorMessage text={t(error)} />
    </div>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.bool,
  label: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
};

Checkbox.defaultProps = {
  disabled: false,
  error: '',
  value: false,
  label: '',
  handleChange: () => {},
};

export default Checkbox;
