import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Button, IconDisabled } from 'shared/components';
import './styles.scss';

const DirtyFormAlert = ({
  dirty,
  confirmText,
  disabled,
  title,
  theme,
  children,
  closeAlert,
  closeCb,
  ...props
}) => {
  const { t } = useTranslation();
  if (!dirty) {
    closeCb();
    return null;
  }

  return createPortal(
    <div className="DirtyFormAlert" {...props}>
      <div className="DirtyFormAlert-inner">
        <div className="DirtyFormAlert-header">
          {title || t('Are you sure you want to discard your changes?')}
        </div>
        <div className="DirtyFormAlert-body">{children}</div>
        <div className="DirtyFormAlert-footer">
          <Button onClick={closeCb} theme={theme} disabled={disabled}>
            {confirmText || t('Discard changes')}
          </Button>
          <Button onClick={closeAlert} theme="link">
            {t('Cancel')}
          </Button>
          <button
            type="button"
            className="DirtyFormAlert-dismiss"
            onClick={closeAlert}
          >
            <IconDisabled height="16px" />
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

DirtyFormAlert.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  closeCb: PropTypes.func.isRequired,
  closeAlert: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  disabled: PropTypes.bool,
  theme: PropTypes.oneOf([
    'default',
    'info',
    'warning',
    'success',
    'error',
    'link',
  ]),
  title: PropTypes.string,
};

DirtyFormAlert.defaultProps = {
  children: '',
  confirmText: '',
  disabled: false,
  theme: 'error',
  title: '',
};

export default DirtyFormAlert;
