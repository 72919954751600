import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DescriptionTable,
  DirtyFormAlert,
  SlidePane,
  SelectedFeaturesTags,
  SelectedCustomFieldsTags,
  IconInfo,
  NumberInput,
  Label,
} from 'shared/components';
import { licenseTypes, errorMsg } from 'shared/constants';
import { validateRequiredNumber } from 'shared/validation';
import {
  displayValue,
  capitalizeFirstLetter,
  displayMaxOveragesValue,
  mapProductsToSelector,
  getDefaultLicensePolicy,
  mapPolicyProductFeatures,
  mapPolicyCustomFields,
  getRemainingLicenses,
} from 'shared/helpers';
import { getInitialProductValue } from './helpers';
import { ProductInformation } from './components';
import './styles.scss';

const getSelectedPolicyFeatures = (selectedPolicy) => {
  const features =
    get(selectedPolicy, 'license_product_feature_templates') || [];
  const productFeatures = get(selectedPolicy, 'product.product_features') || [];

  const list = [];
  features.forEach((f) => {
    const found = productFeatures.find((pf) => pf.id === f.product_feature);
    if (found) {
      list.push({
        ...f,
        name: found.name,
      });
    }
  });
  return list;
};

const getPoliciesList = (policies, products, selectedProduct) => {
  const product = selectedProduct || get(products, '[0]');
  const list = policies.filter(
    (pol) => get(pol, 'product.id') === get(product, 'id'),
  );
  return list;
};

const SelectProductForm = ({
  closeCb,
  handleSubmit,
  isOpen,
  productToEdit,
  title,
  width,
}) => {
  const { t } = useTranslation();
  const counter = useSelector((state) => get(state, 'user.counter') || []);
  const productsListState = useSelector(
    (state) => get(state, 'user.details.products') || [],
  );
  const products = productsListState
    .filter((p) => !p.is_bundle)
    .map((p) => ({ ...p.product, num_of_licenses: p.num_of_license }));
  const userPolicies = useSelector(
    (state) => get(state, 'user.details.license_templates') || [],
  );
  const initialLicensePolicy = getDefaultLicensePolicy(
    products,
    userPolicies,
    productToEdit,
  );

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);

  // ------------- PRODUCT INFORMATION ----------------------------------------------------------------------------------------------------------------------------------
  const [selectedProduct, setSelectedProduct] = useState({
    label: getInitialProductValue('product_name', products, productToEdit),
    value: getInitialProductValue('product_name', products, productToEdit),
    data: productToEdit || get(products, '[0]'),
  });
  const [policies, setPolicies] = useState(
    getPoliciesList(userPolicies, products),
  );
  const [selectedPolicy, setSelectedPolicy] = useState(initialLicensePolicy);
  // product features
  // eslint-disable-next-line no-unused-vars
  const [productFeatures, setProductFeatures] = useState(
    mapPolicyProductFeatures(
      productToEdit || get(products, '[0]'),
      initialLicensePolicy,
    ),
  );
  const [selectedPolicyFeatures, setSelectedPolicyFeatures] = useState(
    getSelectedPolicyFeatures(initialLicensePolicy),
  );
  // custom fields
  // eslint-disable-next-line no-unused-vars
  const [customFields, setCustomFields] = useState(
    mapPolicyCustomFields(
      productToEdit || get(products, '[0]'),
      initialLicensePolicy,
    ),
  );
  // eslint-disable-next-line no-unused-vars
  const [selectedPolicyCustomFields, setSelectedPolicyCustomFields] = useState(
    mapPolicyCustomFields(
      productToEdit || get(products, '[0]'),
      initialLicensePolicy,
    ),
  );
  const [licenseQuantity, setLicenseQuantity] = useState(
    getInitialProductValue('license_num', products, productToEdit, 1),
  );
  const [licenseQuantityError, setLicenseQuantityError] = useState('');
  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const productsForSelector = mapProductsToSelector(products);
  const productAuthMethod =
    get(selectedProduct, 'data.authorization_method') || '';
  const isUserBasedProduct = productAuthMethod === 'user';

  const getOrderData = () => {
    const licensePolicy = policies.find(
      (pp) => pp.code === selectedPolicy.code,
    );
    const product = products.find(
      (p) => p.id === get(selectedProduct, 'data.id'),
    );

    const data = {
      id: get(product, 'id'),
      short_code: get(product, 'short_code'),
      allow_trial: get(product, 'allow_trial'),
      trial_days: get(product, 'trial_days'),
      product_name: get(product, 'product_name'),
      authorization_method: get(product, 'authorization_method'),
      license_num: isUserBasedProduct ? 1 : Number(licenseQuantity),
      license_templates: policies,
      selected_license_policy: licensePolicy,
      selected_features: selectedPolicyFeatures,
      selected_custom_fields: selectedPolicyCustomFields,
    };
    return data;
  };

  const validateLicenseQuantity = async (val) => {
    setLoading(true);
    let errors;

    if (Number(val) > 99) {
      setLicenseQuantityError(`${errorMsg.maxQuantity} 99`);
      return false;
    }

    if (!isUserBasedProduct) {
      const remaining = getRemainingLicenses(
        get(selectedProduct, 'data.id'),
        counter,
      );
      // if null it is unlimited
      if (remaining !== null) {
        const isBeyondLimit = Number(val) > remaining;
        if (isBeyondLimit) {
          setLicenseQuantityError(`${errorMsg.remainingLicenses} ${remaining}`);
          return false;
        }
      }
    }

    try {
      errors = await validateRequiredNumber(val);
      setLoading(false);
      setLicenseQuantityError(errors);
    } catch (err) {
      setLoading(false);
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const handleLicenseQuantityChange = (val) => {
    setDirty(true);
    setLicenseQuantity(val);
    validateLicenseQuantity(val);
  };

  // ------------- SET LICENSE POLICY VALUES ----------------------------------------------------------------------------------------------------------------------------

  const setLicensePolicyValues = (selectedPol, selectedProd) => {
    setProductFeatures(
      mapPolicyProductFeatures(selectedProd.data, selectedPol),
    );
    setSelectedPolicyFeatures(getSelectedPolicyFeatures(selectedPol));

    setCustomFields(mapPolicyCustomFields(selectedProd.data, selectedPol));
    setSelectedPolicyCustomFields(
      mapPolicyCustomFields(selectedProd.data, selectedPol),
    );
  };

  // ------------- PRODUCT INFORMATION METHODS --------------------------------------------------------------------------------------------------------------------------
  const handleProductSelect = (val) => {
    const selectedP = products.find((p) => get(p, 'product_name') === val);
    const defaultPolicy = getDefaultLicensePolicy(
      products,
      userPolicies,
      selectedP,
    );
    setDirty(true);
    // reset state values
    const newSelectedProduct = {
      label: get(selectedP, 'product_name'),
      value: get(selectedP, 'product_name'),
      data: selectedP,
    };
    setSelectedProduct(newSelectedProduct);
    setSelectedPolicy(defaultPolicy);
    setPolicies(getPoliciesList(userPolicies, products, selectedP));
    setLicensePolicyValues(defaultPolicy, newSelectedProduct);
  };

  const handlePolicySelect = (val) => {
    const selectedPol = policies.find((pp) => pp.code === val);
    setSelectedPolicy(selectedPol);
    setLicensePolicyValues(selectedPol, selectedProduct);
  };

  // ------------- SUBMIT METHODS ---------------------------------------------------------------------------------------------------------------------------------------
  const handleStepSubmit = async () => {
    const isQuantityValid = await validateLicenseQuantity(licenseQuantity);
    if (loading || !isQuantityValid) {
      return false;
    }

    const data = getOrderData();
    const isEditing = !!productToEdit || false;
    return handleSubmit(data, isEditing);
  };

  const handleClose = () => {
    if (isDirty) {
      setDirtyFormDisplay(true);
    } else {
      closeCb();
    }
  };

  const shouldShowFeaturesTab = selectedPolicyFeatures.length > 0;
  const shouldShowCustomFieldsTab = selectedPolicyCustomFields.length > 0;
  const isTimeLimited =
    get(selectedPolicy, 'default_license_type') === licenseTypes.time_limited;
  const isSubscription =
    get(selectedPolicy, 'default_license_type') === licenseTypes.subscription;
  const isConsumption =
    get(selectedPolicy, 'default_license_type') === licenseTypes.consumption;
  const hasProductsAndPolicies =
    !!productsForSelector.length && !!userPolicies.length;
  const canBorrow = get(selectedPolicy, 'can_borrow');

  return (
    <SlidePane
      closeCb={handleClose}
      isOpen={isOpen}
      title={title}
      width={width}
    >
      <div className="SelectProductForm">
        <div className="confirm-btn">
          <Button
            theme="success"
            onClick={handleStepSubmit}
            disabled={loading || !hasProductsAndPolicies}
          >
            {t('Confirm')}
          </Button>
        </div>
        <div className="SelectProductForm-main">
          {!hasProductsAndPolicies ? (
            <div className="missing-products-alert">
              <IconInfo height="40px" width="40px" color="#ee5253" />
              <div className="text">
                {t('You have no enabled products and license policies.')}
              </div>
            </div>
          ) : (
            <div className="sections-wrapper">
              <ProductInformation
                products={productsForSelector}
                productPolicies={policies}
                handleProductSelect={handleProductSelect}
                selectedProduct={selectedProduct}
                selectedPolicy={get(selectedPolicy, 'code')}
                handlePolicySelect={handlePolicySelect}
              />
              {!isUserBasedProduct && (
                <div className="section-row">
                  <Label
                    text={t('Number of license keys')}
                    inputId="license-num-input"
                  />
                  <NumberInput
                    handleChange={handleLicenseQuantityChange}
                    value={licenseQuantity}
                    error={licenseQuantityError}
                    min="1"
                    max="1000"
                    id="license-num-input"
                  />
                </div>
              )}
              <DescriptionTable
                details={[
                  {
                    label: t('License Type'),
                    value: displayValue(
                      t(get(selectedPolicy, 'default_license_type')),
                    ),
                  },
                  {
                    label: t('Max activations'),
                    value: get(selectedPolicy, 'allow_unlimited_activations')
                      ? t('Unlimited')
                      : displayValue(get(selectedPolicy, 'max_activations')),
                  },
                  {
                    label: isUserBasedProduct ? t('Max license users') : null,
                    value:
                      get(selectedPolicy, 'unlimited_max_license_users') ||
                      get(selectedPolicy, 'max_license_users') === 0
                        ? t('Unlimited')
                        : displayValue(
                            get(selectedPolicy, 'max_license_users'),
                          ),
                  },
                  {
                    label:
                      isTimeLimited || isSubscription
                        ? t('Valid duration')
                        : null,
                    value: displayValue(get(selectedPolicy, 'valid_duration')),
                  },
                  {
                    label: get(selectedPolicy, 'allow_grace_period')
                      ? t('Grace period')
                      : null,
                    value: `${displayValue(get(selectedPolicy, 'grace_period'))} ${t('hours')}`,
                  },
                  {
                    label: t('Is trial'),
                    value: get(selectedPolicy, 'allow_trial')
                      ? t('Yes')
                      : t('No'),
                  },
                  {
                    label: get(selectedPolicy, 'allow_trial')
                      ? t('Trial days')
                      : null,
                    value: `${get(selectedPolicy, 'trial_days')} days`,
                  },
                  // show consumption values
                  {
                    label: isConsumption ? t('Max consumptions') : null,
                    value: get(selectedPolicy, 'allow_unlimited_consumptions')
                      ? t('Unlimited')
                      : displayValue(get(selectedPolicy, 'max_consumptions')),
                  },
                  {
                    label: isConsumption
                      ? t('Allow negative consumptions')
                      : null,
                    value: get(selectedPolicy, 'allow_negative_consumptions')
                      ? t('Yes')
                      : t('No'),
                  },
                  {
                    label: isConsumption ? t('Allow overages') : null,
                    value: get(selectedPolicy, 'allow_overages')
                      ? t('Yes')
                      : t('No'),
                  },
                  {
                    label: isConsumption ? t('Max overages') : null,
                    value: displayMaxOveragesValue(selectedPolicy),
                  },
                  {
                    label: isConsumption ? t('Reset consumption') : null,
                    value: get(selectedPolicy, 'reset_consumption')
                      ? t('Yes')
                      : t('No'),
                  },
                  {
                    label: isConsumption ? t('Consumption period') : null,
                    value: displayValue(
                      capitalizeFirstLetter(
                        get(selectedPolicy, 'consumption_period'),
                      ),
                    ),
                  },
                  // show everything else
                  {
                    label: get(selectedPolicy, 'enable_maintenance_period')
                      ? t('Maintenance duration')
                      : null,
                    value: displayValue(
                      get(selectedPolicy, 'maintenance_duration'),
                    ),
                  },
                  {
                    label: get(selectedPolicy, 'is_floating')
                      ? t('Offline floating license')
                      : null,
                    value: get(selectedPolicy, 'is_floating')
                      ? t('Yes')
                      : t('No'),
                  },
                  {
                    label: get(selectedPolicy, 'is_floating_cloud')
                      ? t('Is floating cloud')
                      : null,
                    value: get(selectedPolicy, 'is_floating_cloud')
                      ? t('Yes')
                      : t('No'),
                  },
                  {
                    label:
                      get(selectedPolicy, 'is_floating') ||
                      get(selectedPolicy, 'is_floating_cloud')
                        ? t('Max simultaneous license users')
                        : null,
                    value: displayValue(get(selectedPolicy, 'floating_users')),
                  },
                  {
                    label:
                      get(selectedPolicy, 'is_floating') ||
                      get(selectedPolicy, 'is_floating_cloud')
                        ? t('Floating timeout')
                        : null,
                    value: `${displayValue(get(selectedPolicy, 'floating_timeout'))} min`,
                  },
                  {
                    label: canBorrow ? t('Can borrow') : null,
                    value: canBorrow ? t('Yes') : t('No'),
                  },
                  {
                    label: canBorrow ? t('Max borrow time') : null,
                    value: `${displayValue(get(selectedPolicy, 'max_borrow_time'))} ${t('hours')}`,
                  },
                  {
                    label: get(selectedPolicy, 'max_transfers')
                      ? t('Device transfer limit')
                      : null,
                    value: displayValue(get(selectedPolicy, 'max_transfers')),
                  },
                  {
                    label: t('Prevent virtual machine'),
                    value: get(selectedPolicy, 'prevent_vm')
                      ? t('Yes')
                      : t('No'),
                  },
                  {
                    label: shouldShowFeaturesTab ? t('Product features') : null,
                    value: (
                      <SelectedFeaturesTags features={selectedPolicyFeatures} />
                    ),
                  },
                  {
                    label: shouldShowCustomFieldsTab
                      ? t('Custom fields')
                      : null,
                    value: (
                      <SelectedCustomFieldsTags
                        cFields={get(
                          selectedPolicy,
                          'license_custom_field_templates',
                        )}
                      />
                    ),
                  },
                ]}
              />
            </div>
          )}
        </div>
        {isDirtyFormDisplayed && (
          <DirtyFormAlert
            dirty={isDirty}
            closeAlert={() => setDirtyFormDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </SlidePane>
  );
};

SelectProductForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  productToEdit: PropTypes.object,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
};

SelectProductForm.defaultProps = {
  productToEdit: null,
  width: '80%',
};

export default SelectProductForm;
