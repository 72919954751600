import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const FormStepper = ({ children }) => (
  <div className="FormStepper">
    <div className="FormStepper-wrapper">{children}</div>
  </div>
);

FormStepper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
};

FormStepper.defaultProps = {
  children: '',
};

export default FormStepper;
