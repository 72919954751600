import get from 'lodash.get';
import sortBy from 'lodash.sortby';

export const getLicenseCustomFields = (product = {}, fields = []) => {
  const productFields = get(product, 'custom_fields') || [];
  const licenseFields = [];

  if (!Array.isArray(fields) || fields.length === 0) {
    return productFields;
  }

  productFields.forEach((field) => {
    const licenseArr = fields.filter(
      (f) => f.product_custom_field === field.id,
    );
    const sortedArr = sortBy(licenseArr, 'id').reverse();

    if (sortedArr.length !== 0 && sortedArr[0].value !== field.default_value) {
      sortedArr[0].name = field.name;
      licenseFields.push(sortedArr[0]);
    } else {
      licenseFields.push(field);
    }
  });

  return licenseFields;
};
