import React from 'react';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { isUserManager } from 'shared/helpers';
import ManagerLicenseList from './ManagerLicenseList';
import UserLicenseList from './UserLicenseList';

const Licenses = () => {
  const userDetails = useSelector((state) => get(state, 'user.details'));
  const isManager = isUserManager(userDetails);

  if (isManager) {
    return <ManagerLicenseList />;
  }

  return <UserLicenseList />;
};

export default Licenses;
