import React from 'react';
import PropTypes from 'prop-types';
import { ContentLoader } from 'shared/components';
import './styles.scss';

const Button = ({
  children,
  disabled,
  loading,
  onClick,
  size,
  theme,
  ...rest
}) => (
  <button
    className={`Button Button-size-${size} Button-theme-${theme}`}
    type="button"
    disabled={disabled}
    onClick={onClick}
    {...rest}
  >
    <>
      {loading && <ContentLoader />}
      {children}
    </>
  </button>
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  theme: PropTypes.oneOf([
    'default',
    'info',
    'warning',
    'success',
    'error',
    'link',
  ]),
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  onClick: null,
  size: 'md',
  theme: 'default',
};

export default Button;
