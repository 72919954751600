import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabContent, TabsHeader } from 'shared/components';
import { parseObject } from 'shared/helpers';
import { initialPortalData } from 'shared/constants';
import LicenseDetails from 'licenses/LicenseContainer/LicenseDetails';
import ProductFeatures from 'licenses/LicenseContainer/ProductFeatures';
import CustomFields from 'licenses/LicenseContainer/CustomFields';
import LicenseDevices from 'licenses/LicenseContainer/LicenseDevices';
import './styles.scss';

const BundleLicenseItem = ({
  license,
  refetchLicense,
  isLicenseUser,
  product,
}) => {
  const { t } = useTranslation();
  const userDetails = useSelector((state) => get(state, 'user.details'));
  const portal_data = get(userDetails, 'company.portal_data');
  const portalData = parseObject(portal_data, initialPortalData);
  const { hideCustomFields, hideProductFeatures } = portalData;
  return (
    <div className="BundleLicenseItem LicenseContainer">
      <div className="list-header" />
      <div className="BundleLicenseItem-tabs LicenseContainer-tabs">
        <Tabs>
          <TabsHeader>
            <Tab>{t('License details')}</Tab>
            {!hideProductFeatures && <Tab>{t('Product features')}</Tab>}
            {!hideCustomFields && <Tab>{t('Custom fields')}</Tab>}
            <Tab>{t('Devices')}</Tab>
          </TabsHeader>
          <TabContent>
            <LicenseDetails
              license={license}
              getLicense={refetchLicense}
              isLicenseUser={isLicenseUser}
              isBundleItem
            />
          </TabContent>
          {!hideProductFeatures && (
            <TabContent>
              <ProductFeatures license={license} />
            </TabContent>
          )}
          {!hideCustomFields && (
            <TabContent>
              <CustomFields license={license} product={product} />
            </TabContent>
          )}
          <TabContent>
            <LicenseDevices license={license} isLicenseUser={isLicenseUser} />
          </TabContent>
        </Tabs>
      </div>
    </div>
  );
};

BundleLicenseItem.propTypes = {
  license: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  refetchLicense: PropTypes.func.isRequired,
  isLicenseUser: PropTypes.bool.isRequired,
};

export default BundleLicenseItem;
