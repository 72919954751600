import React from 'react';
import Base from './Base';

export default (props) => (
  <Base {...props}>
    <path d="M19.12,28.79A7,7,0,0,1,25,18a7,7,0,0,1,3.1.73l5.2-5.83A20.34,20.34,0,0,0,25,11C12.3,11,2,25,2,25A44.91,44.91,0,0,0,13.29,35.31Z" />
    <path d="M37.21,15l-6,6.73A6.91,6.91,0,0,1,32,25a7,7,0,0,1-7,7,6.86,6.86,0,0,1-2.53-.48l-5.21,5.83A20.07,20.07,0,0,0,25,39c12.7,0,23-14,23-14A45.3,45.3,0,0,0,37.21,15Z" />
    <path d="M12.75,40.67l-1.5-1.34,25-28,1.5,1.34Z" />
  </Base>
);
