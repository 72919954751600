import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ClipboardRow, List } from 'shared/components';
import { hasPermission } from 'shared/helpers';
import { LuRotateCw, LuRefreshCcwDot, LuTrash2, LuClock } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const IconButton = ({ icon: Icon, onClick, disabled, className }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={`icon-button ${className || ''}`}
  >
    <Icon />
  </button>
);

IconButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  disabled: false,
  className: '',
};

const SecretExpirationInfo = ({ attributes }) => {
  if (!attributes) return null;

  const rotatedSecret = get(attributes, 'client.secret.rotated');
  const creationTime =
    parseInt(get(attributes, 'client.secret.creation.time'), 10) * 1000;
  const expirationPeriod = get(attributes, 'SECRET_EXPIRATION_PERIOD');
  const rotationPeriod = get(attributes, 'SECRET_ROTATED_EXPIRATION_PERIOD');

  // If no expiration period is set, this is a perpetual secret
  if (!expirationPeriod) {
    return (
      <div className="secret-expiration-info">
        <div className="expiration-row status-normal">
          <LuClock className="expiration-icon" />
          <div className="expiration-details">
            <div className="expiration-main">
              Perpetual secret (never expires)
            </div>
            <div className="expiration-secondary">
              {`Created: ${moment(creationTime).format('MMM D, YYYY HH:mm')}`}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const expirationPeriodMs = parseInt(expirationPeriod, 10) * 1000;
  const rotationPeriodMs = parseInt(rotationPeriod, 10) * 1000;

  const creationDate = moment(creationTime);
  const expirationDate = moment(creationTime + expirationPeriodMs);
  const rotationDate = rotationPeriod
    ? moment(creationTime + rotationPeriodMs)
    : null;
  const now = moment();

  const getExpirationText = () => {
    if (now.isAfter(expirationDate)) {
      return 'Secret has expired';
    }

    const diffSeconds = expirationDate.diff(now, 'seconds');
    const diffMinutes = expirationDate.diff(now, 'minutes');
    const diffHours = expirationDate.diff(now, 'hours');
    const diffDays = expirationDate.diff(now, 'days');

    if (diffDays > 0) {
      return `Expires in ${diffDays} ${diffDays === 1 ? 'day' : 'days'}`;
    }

    if (diffHours > 0) {
      return `Expires in ${diffHours} ${diffHours === 1 ? 'hour' : 'hours'}`;
    }

    if (diffMinutes > 0) {
      return `Expires in ${diffMinutes} ${
        diffMinutes === 1 ? 'minute' : 'minutes'
      }`;
    }

    return `Expires in ${diffSeconds} ${
      diffSeconds === 1 ? 'second' : 'seconds'
    }`;
  };

  const getRotationText = () => {
    if (!rotationDate || !rotatedSecret) return null;

    if (now.isAfter(rotationDate)) {
      return `Rotated secret (${rotatedSecret}) has expired`;
    }

    const diffSeconds = rotationDate.diff(now, 'seconds');
    const diffMinutes = rotationDate.diff(now, 'minutes');
    const diffHours = rotationDate.diff(now, 'hours');
    const diffDays = rotationDate.diff(now, 'days');

    if (diffDays > 0) {
      return `Rotated secret (${rotatedSecret}) expires in ${diffDays} ${
        diffDays === 1 ? 'day' : 'days'
      }`;
    }

    if (diffHours > 0) {
      return `Rotated secret (${rotatedSecret}) expires in ${diffHours} ${
        diffHours === 1 ? 'hour' : 'hours'
      }`;
    }

    if (diffMinutes > 0) {
      return `Rotated secret (${rotatedSecret}) expires in ${diffMinutes} ${
        diffMinutes === 1 ? 'minute' : 'minutes'
      }`;
    }

    return `Rotated secret (${rotatedSecret}) expires in ${diffSeconds} ${
      diffSeconds === 1 ? 'second' : 'seconds'
    }`;
  };

  let statusClass = 'status-normal';
  if (now.isAfter(expirationDate)) {
    statusClass = 'status-expired';
  } else if (rotationDate && now.isAfter(rotationDate)) {
    statusClass = 'status-warning';
  }

  return (
    <div className="secret-expiration-info">
      <div className={`expiration-row ${statusClass}`}>
        <LuClock className="expiration-icon" />
        <div className="expiration-details">
          <div className="expiration-main">{getExpirationText()}</div>
          <div className="expiration-secondary">
            {`Created: ${creationDate.format('MMM D, YYYY HH:mm')}${
              expirationPeriod
                ? ` • Expires: ${expirationDate.format('MMM D, YYYY HH:mm')}`
                : ''
            }`}
          </div>
          {!now.isAfter(expirationDate) && rotatedSecret && (
            <div className="rotation-info">{getRotationText()}</div>
          )}
        </div>
      </div>
    </div>
  );
};

SecretExpirationInfo.propTypes = {
  attributes: PropTypes.shape({
    SECRET_EXPIRATION_PERIOD: PropTypes.string,
    SECRET_ROTATED_EXPIRATION_PERIOD: PropTypes.string,
    SECRET_REMAINING_ROTATION_PERIOD: PropTypes.string,
    'client.secret.creation.time': PropTypes.string,
    'client.secret.rotated': PropTypes.string,
  }),
};

SecretExpirationInfo.propTypes = {
  attributes: PropTypes.shape({
    SECRET_EXPIRATION_PERIOD: PropTypes.string,
    SECRET_ROTATED_EXPIRATION_PERIOD: PropTypes.string,
    SECRET_REMAINING_ROTATION_PERIOD: PropTypes.string,
    'client.secret.creation.time': PropTypes.string,
  }),
};

export const OAuthCredentials = ({
  oAuthClient,
  isLoading,
  handleManageSettingsClick,
  onRotateClick,
  onRefreshClick,
  onDeleteClick,
}) => {
  const { t } = useTranslation();
  const permissionsList = useSelector((state) =>
    get(state, 'user.details.permissions')
  );
  const canManageOAuth = hasPermission(
    permissionsList,
    'manage_license_oauth_clients'
  );

  const columns = [
    {
      Header: t('Client ID'),
      sortable: false,
      accessor: 'clientId',
      Cell: (cellData) => (
        <div className="credential-cell">
          <ClipboardRow
            id="clientID"
            value={get(cellData, 'value')}
            hideLabel
            label={t('Client ID')}
          />
        </div>
      ),
    },
    {
      Header: t('Client Secret'),
      sortable: false,
      accessor: 'secret',
      Cell: (cellData) => (
        <div className="credential-cell">
          <ClipboardRow
            id="clientSecret"
            value={get(cellData, 'value')}
            hideLabel
            label={t('Client Secret')}
          />
        </div>
      ),
    },
    {
      Header: t('Secret Expiration'),
      sortable: false,
      accessor: 'secret',
      Cell: (cellData) => (
        <div className="credential-cell">
          <div className="credential-info">
            <SecretExpirationInfo attributes={oAuthClient.attributes} />
          </div>
        </div>
      ),
    },
  ];

  if (canManageOAuth) {
    columns.push(
      {
        Header: t('Refresh'),
        sortable: false,
        className: 'text-center',
        headerClassName: 'text-center',
        accessor: 'actions',
        width: 100,
        Cell: () => (
          <div className="credential-actions">
            <IconButton
              icon={LuRotateCw}
              onClick={() => handleManageSettingsClick(onRefreshClick)}
              disabled={isLoading}
              className="icon-button--refresh"
            />
          </div>
        ),
      },
      {
        Header: t('Rotate'),
        sortable: false,
        className: 'text-center',
        headerClassName: 'text-center',
        accessor: 'actions',
        width: 100,
        Cell: () => (
          <div className="credential-actions">
            <IconButton
              icon={LuRefreshCcwDot}
              onClick={() => handleManageSettingsClick(onRotateClick)}
              disabled={isLoading}
              className="icon-button--rotate"
            />
          </div>
        ),
      },
      {
        Header: t('Delete'),
        sortable: false,
        className: 'text-center',
        headerClassName: 'text-center',
        accessor: 'actions',
        width: 100,
        Cell: () => (
          <div className="credential-actions">
            <IconButton
              icon={LuTrash2}
              onClick={() => handleManageSettingsClick(onDeleteClick)}
              disabled={isLoading}
              className="icon-button--delete"
            />
          </div>
        ),
      }
    );
  }

  return (
    <div className="oauth-actions">
      <List
        columns={columns}
        data={[oAuthClient]}
        minRows={1}
        showPagination={false}
        loading={isLoading}
        clickable={false}
      />
    </div>
  );
};

OAuthCredentials.propTypes = {
  oAuthClient: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    secret: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleManageSettingsClick: PropTypes.func.isRequired,
  onRefreshClick: PropTypes.func.isRequired,
  onRotateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default OAuthCredentials;
