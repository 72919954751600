import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { DescriptionTable } from 'shared/components';
import { environmentOptions } from 'shared/constants';
import ProductDownloadItem from './ProductDownloadItem';
import './styles.scss';

const getEnvLabel = (val) => {
  const option = environmentOptions.find((e) => e.value === val);
  return get(option, 'label');
};

const ProductDownloadTable = ({ files }) => {
  const { t } = useTranslation();
  const data = Object.keys(files)
    .map((k) => files[k])
    .sort();

  return (
    <div className="ProductDownloadTable">
      <div className="headings">{t('Product download')}</div>
      <div className="table">
        <DescriptionTable
          details={data.map((d) => ({
            label: getEnvLabel(get(d, 'environment')),
            value: <ProductDownloadItem file={d} />,
          }))}
        />
      </div>
    </div>
  );
};

ProductDownloadTable.propTypes = {
  files: PropTypes.object.isRequired,
};

export default ProductDownloadTable;
