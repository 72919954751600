import { emailRegex, errorMsg } from 'shared/constants';

export const validateUserEmail = (val) => {
  let error = '';
  if (!val) {
    error = errorMsg.required;
    return error;
  }

  if (!emailRegex.test(val)) {
    error = errorMsg.invalidEmail;
    return error;
  }
  return error;
};

export const validatePassword = (val) => {
  let error = '';

  if (!val) {
    error = errorMsg.required;
    return error;
  }

  if (val.length < 8) {
    error = errorMsg.passCharNum;
    return error;
  }

  return error;
};
