import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { LoadingIndicator } from 'shared/components';
import Option from './Option';
import './styles.scss';

const Selector = ({
  disabled,
  handleChange,
  loading,
  multi,
  options,
  value,
  labelKey,
  required,
  isClearable,
  valueKey,
  ...rest
}) => {
  const handleSelectChange = (option) => handleChange(option[valueKey]);
  let valueForSelect = null;

  if (value) {
    valueForSelect = !multi
      ? options.find((o) => o[valueKey] === value)
      : value;
  }

  return (
    <div className="Selector">
      <Select
        options={options}
        className="selector-container"
        classNamePrefix="selector-inner"
        components={{ Option }}
        value={valueForSelect}
        onChange={handleSelectChange}
        isMulti={multi}
        isDisabled={disabled}
        isClearable={isClearable}
        {...rest}
      />
      {!disabled && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            height: 0,
            position: 'absolute',
            bottom: '10px',
            left: '20px',
            pointerEvents: 'none',
          }}
          value={valueForSelect || ''}
          required={required}
          readOnly
        />
      )}
      {loading && <LoadingIndicator />}
    </div>
  );
};

Selector.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  loading: PropTypes.bool,
  isClearable: PropTypes.bool,
  multi: PropTypes.bool,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  required: PropTypes.bool,
};

Selector.defaultProps = {
  disabled: false,
  handleChange: () => {},
  loading: false,
  isClearable: false,
  multi: false,
  value: undefined,
  labelKey: null,
  valueKey: 'value',
  required: false,
};

export default Selector;
