import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { Modal, Notification } from 'shared/components';
import { removeLicenseUser } from './actions';
import './styles.scss';

const RemoveLicenseUserForm = ({
  closeCb,
  confirmCb,
  license,
  userToRemove,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const licenseID = get(license, 'id');
    const userID = get(userToRemove, 'id');

    removeLicenseUser(licenseID, userID)
      .then(() => {
        confirmCb();
        Notification(
          'success',
          t('Changes saved successfully'),
          t('License user has been removed'),
        );
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          t('Your changes were not saved'),
          t('There was an error while saving your changes'),
        );
      });
  };

  return (
    <Modal
      title={t('Are you sure?')}
      closeCb={closeCb}
      confirmCb={handleSubmit}
      disabled={loading}
      size="sm"
    >
      <div className="RemoveLicenseUserForm">
        <div className="heading">
          {t('This will remove license user from this licence.')}
        </div>
        <div className="bottom">
          {`${t('License user')}:`}
          &nbsp;
          <span>{get(userToRemove, 'true_email')}</span>
        </div>
      </div>
    </Modal>
  );
};

RemoveLicenseUserForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  license: PropTypes.object.isRequired,
  userToRemove: PropTypes.object.isRequired,
};

export default RemoveLicenseUserForm;
