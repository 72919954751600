import { actionTypes } from './constants';

export const newOrderReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.existingOrderPrefill:
      return {
        ...state,
        ...action.data,
      };
    case actionTypes.customerDetails:
      return {
        ...state,
        ...action.data,
      };
    case actionTypes.selectedProducts:
      return {
        ...state,
        ...action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export const prefillExistingOrderDetails = (data) => ({
  type: actionTypes.existingOrderPrefill,
  data,
});

export const setCustomerDetails = (data) => ({
  type: actionTypes.customerDetails,
  data,
});

export const setSelectedProducts = (data) => ({
  type: actionTypes.selectedProducts,
  data,
});
