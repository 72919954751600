import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
} from 'shared/components';
import { generatePass } from 'shared/helpers';
import * as validation from './validation';
import { setLicenseUserPassword } from './actions';

const LicenseUserPasswordForm = ({
  closeCb,
  confirmCb,
  licenseUser,
  title,
}) => {
  const { t } = useTranslation();
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(generatePass());
  const [passwordError, setPasswordError] = useState('');

  const validatePasword = (val) => {
    const isInvalid = validation.validatePassword(val);

    if (isInvalid) {
      setPasswordError(isInvalid);
      return false;
    }

    setPasswordError('');
    return true;
  };

  const handlePasswordChange = (val) => {
    setDirty(true);
    setPassword(val);
    validatePasword(val);
  };

  const validateForm = () => {
    const isPasswordValid = validatePasword(password);
    return isPasswordValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isFormValid = validateForm();

    if (!isFormValid || loading) {
      return;
    }

    setDirty(false);
    setLoading(true);

    const data = {
      password,
    };
    const userId = get(licenseUser, 'id');

    setLicenseUserPassword(userId, data)
      .then(() => {
        confirmCb();
        Notification(
          'success',
          t('Changes saved successfully'),
          t('License user password has been changed'),
        );
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          t('Your changes were not saved'),
          t('There was an error while saving your changes'),
        );
      });
  };

  const handleClose = () => {
    if (!dirty) {
      closeCb();
    } else {
      setDirtyFormAlertDisplay(true);
    }
  };

  return (
    <Modal
      title={title}
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      size="sm"
    >
      <div className="LicenseUserPasswordForm">
        <div>
          <Label text={t('Email')} inputId="email" />
          <TextInput
            id="email"
            value={get(licenseUser, 'true_email')}
            disabled
            handleChange={() => {}}
          />
        </div>
        <div>
          <Label text={t('New password')} inputId="password" />
          <TextInput
            id="password"
            value={password}
            error={passwordError}
            handleChange={handlePasswordChange}
          />
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

LicenseUserPasswordForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  licenseUser: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default LicenseUserPasswordForm;
