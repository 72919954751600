import React from 'react';
import PropTypes from 'prop-types';
import { IconCheckmark } from 'shared/components';
import './styles.scss';

const FormStepperItem = ({
  description,
  isActive,
  isCompleted,
  step,
  title,
}) => (
  <div className={`FormStepperItem ${isActive ? 'active' : ''}`}>
    <div className="FormStepperItem-wrapper">
      <div className="status">
        {isCompleted ? (
          <div className="completed">
            <IconCheckmark color="#10ac84" height="26px" width="26px" />
          </div>
        ) : (
          <div className="stepNumber">{step}</div>
        )}
      </div>
      <div className="meta">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
    </div>
    <div className="triangle-right" />
  </div>
);

FormStepperItem.propTypes = {
  description: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

FormStepperItem.defaultProps = {
  description: '',
};

export default FormStepperItem;
