/* eslint-disable react/prop-types */
import React from 'react';

function withClose(Component) {
  class ClickContainer extends React.Component {
    componentDidMount() {
      document.addEventListener('click', this.handleClose);
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleClose);
    }

    handleClose = (e) => {
      const { forwardedRef, close } = this.props;
      if (forwardedRef.current && forwardedRef.current.contains(e.target)) {
        return;
      }
      e.stopPropagation();
      close();
    };

    render() {
      const { forwardedRef, ...rest } = this.props;
      return <Component forwardedRef={forwardedRef} {...rest} />;
    }
  }

  return React.forwardRef((props, ref) => (
    <ClickContainer {...props} forwardedRef={ref} />
  ));
}

export default withClose;
