import React, { useState, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';
import { Dropdown, IconMenu } from 'shared/components';
import './styles.scss';

const ProfileNavigation = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const popupRef = useRef();
  const [isMenuDisplayed, setMenuDisplay] = useState(false);

  const toggleMenu = () => setMenuDisplay(!isMenuDisplayed);

  return (
    <div className="ProfileNavigation">
      <div className="profile-label">
        <div className="sidebar-item" onClick={toggleMenu} role="presentation">
          <IconMenu
            height="22px"
            width="22px"
            color="#fff"
            stroke="#fff"
            strokeWidth="2"
          />
        </div>
      </div>
      {isMenuDisplayed && (
        <Dropdown ref={popupRef} close={toggleMenu}>
          <ul className="links">
            <li>
              <button
                className="li-content"
                onClick={() => auth.signoutRedirect()}
                type="button"
              >
                {t('Logout')}
              </button>
            </li>
          </ul>
        </Dropdown>
      )}
    </div>
  );
};

export default ProfileNavigation;
