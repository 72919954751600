import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const DescriptionCard = ({ children, size, theme }) => (
  <div className={`DescriptionCard ${size} ${theme}`}>{children}</div>
);

DescriptionCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  size: PropTypes.oneOf(['lg', 'sm']),
  theme: PropTypes.oneOf(['success', 'warning', 'info', 'error', 'default']),
};

DescriptionCard.defaultProps = {
  size: 'lg',
  theme: 'default',
};

export default DescriptionCard;
